import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchVendors = () => {
  return LibraryAPI.get({ params: { library: 26 } });
};
export const useFetchVendors = () => {
  return useQuery({
    queryKey: ["vendors"],
    queryFn: fetchVendors,
  });
};
