import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchCategories = () => {
  return LibraryAPI.get({ params: { library: 0 } });
};
export const useFetchCategories = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });
};
