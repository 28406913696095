import { useQuery } from "@tanstack/react-query";
import { AppointmentsByDateAPI } from "api";

const fetchAppointmentsByDates = (from, to) => {
  return AppointmentsByDateAPI.get({ params: { from, to } });
};
export const useFetchAppointmentsByDates = (from, to) => {
  return useQuery({
    queryKey: ["appointmentsByDates", from, to],
    queryFn: () => fetchAppointmentsByDates(from, to),
  });
};
