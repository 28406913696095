import FalconLightBoxGallery from "components/common/FalconLightBoxGallery";
import IconButton from "components/common/IconButton";
import React, { useState } from "react";
import { Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import img1 from "assets/img/generic/4.jpg";
import img2 from "assets/img/generic/5.jpg";
import img11 from "assets/img/generic/11.jpg";
import img3 from "assets/img/gallery/4.jpg";
import img4 from "assets/img/gallery/5.jpg";
import img5 from "assets/img/gallery/3.jpg";
import UploadFilesModal from "./add-lab-report/UploadReports";

export default function GalleryView() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [images, setImages] = useState([img1, img2]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center  bg-body-tertiary mb-2 p-3">
        <h6 className="fs-10 mb-0 text-nowrap py-2 py-xl-0 ">Lab Reports</h6>
        <div className="d-flex gap-2">
          <OverlayTrigger
            overlay={
              <Tooltip
                style={{ position: "fixed" }}
                id="overlay-trigger-example"
              >
                New
              </Tooltip>
            }
          >
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={() => {
                setIsOpenModal(!isOpenModal);
              }}
            >
              <span className="d-none d-sm-inline-block ms-1">New</span>
            </IconButton>
          </OverlayTrigger>
        </div>
      </div>
      <FalconLightBoxGallery images={images}>
        {(setImgIndex) => (
          <Row className="g-2">
            <Col xs={6}>
              <Image
                src={images[0]}
                fluid
                rounded
                className="cursor-pointer"
                onClick={() => setImgIndex(0)}
              />
            </Col>
            <Col xs={6}>
              <Image
                src={images[1]}
                fluid
                rounded
                className="cursor-pointer"
                onClick={() => setImgIndex(1)}
              />
            </Col>
            <Col xs={4}>
              <Image
                src={images[2]}
                fluid
                rounded
                className="cursor-pointer"
                onClick={() => setImgIndex(2)}
              />
            </Col>
            <Col xs={4}>
              <Image
                src={images[3]}
                fluid
                rounded
                className="cursor-pointer"
                onClick={() => setImgIndex(3)}
              />
            </Col>
            <Col xs={4}>
              <Image
                src={images[4]}
                fluid
                rounded
                className="cursor-pointer"
                onClick={() => setImgIndex(4)}
              />
            </Col>
          </Row>
        )}
      </FalconLightBoxGallery>

      <UploadFilesModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </div>
  );
}
