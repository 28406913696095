import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useAppContext } from "Main";
import { useFetchPatients } from "hooks/useFetchPatients";
import IconButton from "components/common/IconButton";
import PatientAddModal from "./PatientAddModal";
import { useFetchPhysicians } from "hooks/useFetchPhysicians";
import * as yup from "yup";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { AppointmentsAPI } from "api";
import { FormProvider, useForm } from "react-hook-form";
import {
  format,
  formatISO,
  isPast,
  isSameDay,
  setHours,
  setMinutes,
  startOfDay,
} from "date-fns";
import { useFetchAppointmentsByIDs } from "hooks/useFetchAppointments";
import { useFetchClinics } from "hooks/useFetchClinics";
import { disabledDates } from "./utils";

const schema = yup
  .object({
    physicianId: yup.string().required("This field is required."),
    patientId: yup.string().required("This field is required."),
    // appointmentTime: yup.string().required("This field is required."),
    instructions: yup.string(),
    status: yup.string(),
    active: yup.boolean(),
  })
  .required();

const AddScheduleModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  modalEventContent,
  setModalEventContent,
  dateOfEvent,
}) => {
  const {
    config: { isDark },
  } = useAppContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: patientsData,
    isLoading: patientsLoading,
    isFetching: patientsFetching,
  } = useFetchPatients();

  const { data: clinicsData } = useFetchClinics();

  const {
    data: physiciansData,
    isLoading: physiciansLoading,
    isFetching: physiciansFetching,
  } = useFetchPhysicians(clinicsData?.data?.data[0]?.id);

  // const [formData, setFormData] = useState({});

  // const [isOpenPatientModal, setIsOpenPatientModal] = useState(false);
  const [isOpenPatientModal, setIsOpenPatientModal] = useState(false);
  const [modalPatientContent, setModalPatientContent] = useState({});
  const [modelState, setModelState] = useState({});
  const [appointmentDate, setAppointmentDate] = useState(null);

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      status: "0",
    },
  });
  const {
    handleSubmit,
    reset,
    watch,
    register,
    setValue,
    formState: { errors },
  } = methods;

  const getMinTime = (date) => {
    if (isSameDay(date, new Date())) {
      return new Date();
    }
    return startOfDay(new Date());
  };

  const isPastDate = (date) => {
    return isPast(date);
  };

  const watchStatus = watch("status");

  // useEffect(() => {
  //   if (isOpenScheduleModal && dateOfEvent) {
  //     const formattedDate = format(new Date(dateOfEvent), "yyyy-MM-dd'T'HH:mm");
  //     setValue("appointmentTime", formattedDate);
  //   }
  // }, [dateOfEvent]);

  useEffect(() => {
    // Check if the modal is open and there is modal event content
    if (isOpenScheduleModal && modalEventContent) {
      const { title, start, id } = modalEventContent?.event || {};
      const { physicianId, patientId, description, status, appointmentTime } =
        modalEventContent?.event?.extendedProps || {};

      const formattedDate = start
        ? format(new Date(start), "MM-dd-yyyy hh:mm aa")
        : null;
      setAppointmentDate(formattedDate);

      // const formattedDate = format(new Date(start), "yyyy-MM-dd'T'HH:mm");
      // setValue("appointmentTime", formattedDate);
      setValue("instructions", description);
      setValue("physicianId", physicianId);
      setValue("patientId", patientId);
      setValue("status", status);
      setModelState(modalEventContent);
    }
  }, [modalEventContent]);

  // // Log modelState when it changes
  // useEffect(() => {
  //   console.log(modelState);
  // }, [modelState]);

  // useEffect(() => {
  //   if (modalEventContent !== undefined) {
  //     setModelState(modalEventContent);
  //   }
  // }, [modalEventContent]);

  // let value = modelState?.event?.extendedProps?.physicianId;

  // console.log(modelState?.event?.extendedProps?.physicianId);

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal);
    reset();
    setModelState(null);
    setModalEventContent(null);
    setAppointmentDate(null);
  };

  // const handleChange = ({ target }) => {
  //   let name = target.name;
  //   let value = target.value;
  //   // let value = name === "allDay" ? target.checked : target.value;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(formData);

  //   // setInitialEvents([...initialEvents, { ...formData }]);
  //   // setIsOpenScheduleModal(false);
  // };

  // const {
  //   data: appointmentData,
  //   isLoading: appointmentLoading,
  //   isFetching: appointmentFetching,
  // } = useFetchAppointmentsByIDs(0, id);

  // if (patientsLoading || physiciansLoading) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  // if (modalEventContent && !modelState) {
  //   console.log("yes");
  //   return <Spinner variant="primary" animation="border" />;
  // }

  // useEffect(() => {
  //   if (isOpenScheduleModal) {
  //     setFormData({
  //       ...formData,
  //       start: scheduleStartDate,
  //       end: scheduleEndDate,
  //     });
  //   } else {
  //     setScheduleStartDate(null);
  //     setScheduleEndDate(null);
  //   }
  // }, [isOpenScheduleModal, scheduleStartDate, scheduleEndDate]);

  // console.log(id);
  // console.log(appointmentData?.data.data);

  const onSubmit = async (data) => {
    let myData = {
      ...data,
      id: modelState ? modelState?.event?.id : 0,
      status: data.status ? data.status : "0",
    };
    myData.appointmentTime = appointmentDate
      ? format(new Date(appointmentDate), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", {
          timeZone: "UTC",
        })
      : null;

    // {
    //   myData.id = modelState ? modelState?.event?.id : 0;
    // }

    console.log(myData);

    if (
      myData.appointmentTime == null ||
      myData.appointmentTime == undefined ||
      myData.appointmentTime == ""
    ) {
      toast.error("Please Provide Date.", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      return;
    }

    try {
      await AppointmentsAPI.post(myData);
      toast.success("Record updated successfully", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      queryClient.invalidateQueries(["appointmentsByIDs"]); // Invalidate and refetch
      handleClose();
    } catch (error) {
      toast.error("Something went wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  // const handleDateChange = (e) => {
  //   const formattedDate = format(
  //     new Date(e.target.value),
  //     "yyyy-MM-dd'T'HH:mm:ss.SSSX"
  //   );
  //   setValue("appointmentTime", formattedDate);
  // };

  const formatDateTimeLocal = (date) => {
    const pad = (number) => number.toString().padStart(2, "0");
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const minDate = formatDateTimeLocal(new Date());

  const isDateDisabled = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    return disabledDates.includes(formattedDate);
  };

  return (
    <>
      <Modal
        show={isOpenScheduleModal}
        onHide={handleClose}
        contentClassName="border"
      >
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header
              closeButton
              closeVariant={isDark ? "white" : undefined}
              className="bg-body-tertiary px-x1 border-bottom-0"
            >
              <Modal.Title as="h5">Appointment</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-x1">
              <Form.Group className="mb-3" controlId="physicianInput">
                <Form.Label className="fs-9">
                  Physician <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  name="className"
                  isInvalid={!!errors.physicianId}
                  {...register("physicianId")}
                  // onChange={handleChange}
                >
                  <option value="">Select</option>
                  {physiciansData?.data?.data.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.physicianId?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="patientInput">
                <div className="d-flex align-items-center gap-1">
                  <Form.Label className="fs-9">
                    Patient <span style={{ color: "red" }}>*</span>{" "}
                  </Form.Label>
                  <Form.Label className="fs-9">
                    {" "}
                    <IconButton
                      variant="primary"
                      size="sm"
                      icon="plus"
                      className="rounded-circle mx-1 px-2"
                      transform="shrink-3"
                      onClick={() => {
                        setIsOpenPatientModal(!isOpenPatientModal);
                      }}
                    ></IconButton>{" "}
                  </Form.Label>
                </div>
                <Form.Select
                  name="patient"
                  isInvalid={!!errors.patientId}
                  {...register("patientId")}
                  // onChange={handleChange}
                >
                  <option value="">Select</option>
                  {patientsData?.data?.data?.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                      {/* -  {d?.age} */} - {d.mobile}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.patientId?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="startDate">
                <Form.Label className="fs-9">
                  Date & Time <span style={{ color: "red" }}>*</span>
                </Form.Label>
                {/* <Form.Control
                  type="datetime-local"
                  placeholder=""
                  autoComplete="off"
                  min={minDate}
                  onChange={handleDateChange}
                  isInvalid={!!errors.appointmentTime}
                  {...register("appointmentTime")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.appointmentTime?.message}
                </Form.Control.Feedback> */}
                <DatePicker
                  selected={appointmentDate ? new Date(appointmentDate) : null}
                  onChange={(date) => setAppointmentDate(date)}
                  className="form-control"
                  dayClassName={(date) =>
                    isDateDisabled(date)
                      ? "react-datepicker__day--disabled"
                      : undefined
                  }
                  filterDate={(date) => !isDateDisabled(date)}
                  placeholderText="mm-dd-yyyy hh:mm tt"
                  dateFormat="MM-dd-yyyy hh:mm aa"
                  showTimeSelect
                  timeIntervals={5}
                  minDate={new Date()}
                  minTime={getMinTime(
                    appointmentDate ? new Date(appointmentDate) : new Date()
                  )}
                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                  disabled={isPastDate(appointmentDate) && watchStatus != "0"}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="instructions">
                <Form.Label className="fs-9">Special Instructions</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="instructions"
                  isInvalid={!!errors.instructions}
                  {...register("instructions")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.instructions?.message}
                </Form.Control.Feedback>
              </Form.Group>
              {modelState?.event && (
                <Form.Group className="mb-3" controlId="label">
                  <Form.Label className="fs-9">Status</Form.Label>
                  <Form.Select
                    name="status"
                    isInvalid={!!errors.status}
                    // defaultValue={0}
                    {...register("status")}
                  >
                    <option value="">Select</option>
                    <option value={0}>In Progress</option>
                    <option value={1}>Draft</option>
                    <option value={2}>Completed</option>
                  </Form.Select>
                </Form.Group>
              )}
            </Modal.Body>
            <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
              {/* <Link to="#!" className="me-3 text-600">
            More options
          </Link> */}
              <div onClick={handleClose} className="px-4 mx-0 cursor-pointer">
                Cancel
              </div>
              <Button
                variant="primary"
                type="submit"
                // onClick={handleSubmit}
                className="px-4 mx-0"
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </FormProvider>
      </Modal>
      <PatientAddModal
        isOpenPatientModal={isOpenPatientModal}
        setIsOpenPatientModal={setIsOpenPatientModal}
        modalPatientContent={modalPatientContent}
      />
    </>
  );
};

// AddScheduleModal.propTypes = {
//   setIsOpenScheduleModal: PropTypes.func.isRequired,
//   isOpenScheduleModal: PropTypes.bool.isRequired,
//   setInitialEvents: PropTypes.func,
//   initialEvents: PropTypes.array,
//   // scheduleDate: PropTypes.instanceOf(Date),
//   // setScheduleDate: PropTypes.func.isRequired,
//   // scheduleEndDate: PropTypes.instanceOf(Date),
//   // setScheduleEndDate: PropTypes.func.isRequired,
// };

export default AddScheduleModal;
