import { yupResolver } from "@hookform/resolvers/yup";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import VitalBasicInformation from "./VitalBasicInformation";
import VitalHeader from "./VitalHeader";

const schema = yup
  .object({
    type: yup.string().required("This field is required."),
    // name: yup.string().required("This field is required."),
    // startDate: yup.string().required("This field is required."),
    // endDate: yup.string().required("This field is required."),
  })
  .required();

const AddVital = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: location.state
      ? {
          type: location.state.type || "",
          // name: location.state.name || "",
          // startDate: location.state.active || false,
          // endDate: location.state.active || false,
        }
      : {},
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = { ...data };

    {
      myData.id = location?.state ? location?.state?.id : 0;
      myData.library = 0;
      myData.phone = "";
      myData.email = "";
      myData.logo = "";
      myData.level = "";
      myData.strength = "";
    }

    // try {
    //   await LibraryAPI.post(myData);
    //   toast.success("Record updated successfully", {
    //     position: customSettings.toastPosition,
    //     autoClose: customSettings.toastClose,
    //   });

    //   // ------- Get all object keys form data and set empty values to reset ------------
    //   const keys = Object.keys(data);
    //   for (const key of keys) {
    //     submittedValues[key] = "";
    //   }
    //   reset({ ...submittedValues });
    //   navigate(-1);
    // } catch (error) {
    //   toast.error("Something went wrong", {
    //     position: customSettings.toastPosition,
    //     autoClose: customSettings.toastClose,
    //   });
    //   console.log(error);
    // }
  };

  // useEffect(() => {
  //   if (location.state) {
  //     reset(location.state);
  //   }
  // }, []);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <VitalHeader />
            </Col>
            <Col lg={12}>
              <VitalBasicInformation />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
            {/* <Col xs={12}>
              <CategoryFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddVital;
