import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchSpaceAddresses = () => {
  return LibraryAPI.get({ params: { library: 9 } });
};
export const useFetchSpaceAddresses = () => {
  return useQuery({
    queryKey: ["space-addresses"],
    queryFn: fetchSpaceAddresses,
  });
};
