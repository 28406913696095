import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchLocationsAddresses = () => {
  return LibraryAPI.get({ params: { library: 22 } });
};
export const useFetchLocationAddresses = () => {
  return useQuery({
    queryKey: ["locations-addresses"],
    queryFn: fetchLocationsAddresses,
  });
};
