import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Nav, Navbar, Row, Col, Spinner } from "react-bootstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "config";
import Flex from "components/common/Flex";
import Logo from "components/common/Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
import routes from "routes/siteMaps";
import { capitalize } from "helpers/utils";
import NavbarTopDropDownMenus from "components/navbar/top/NavbarTopDropDownMenus";
import PurchaseCard from "./PurchaseCard";
import bgNavbar from "assets/img/generic/bg-navbar.png";
import { useAppContext } from "Main";
import { MenusAPI, RolePermissionsAPI } from "api";
import {
  filterAndSortMenuItems,
  useFilterAndSortMenuItems,
} from "utils/menuUtils";

const NavbarVertical = () => {
  const [menuState, setMenuState] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);

  const { nestedMenuItems } = useFilterAndSortMenuItems();

  // console.log(nestedMenuItems);

  // nestedMenuItems.push({
  //   name: "Abc",
  //   parentId: "0",
  //   sortOrder: 99,
  //   id: 50,
  //   to: "/",
  //   exact: true,
  //   active: true,
  // });

  // console.log(nestedMenuItems);

  // console.log(nestedMenuItems);

  // let customizedMenu = menuState.map((data) => ({
  //   children: [
  //     {
  //       name: data[0]?.name,
  //       active: true,
  //       icon: "",
  //       children: menuState.map((sub) => ({
  //         name: sub.name,
  //         to: sub.url,
  //         exact: true,
  //         active: true,
  //       })),
  //     },
  //   ],
  // }));

  // const parents = menuState.filter((item) => item.parentId === 0);
  // const children = menuState.filter((item) => item.parentId !== 0);
  // const sortedParents = parents.sort((a, b) => a.sortOrder - b.sortOrder);
  // // Map children to their respective parents
  // const nestedData = sortedParents.map((parent) => {
  //   return {
  //     ...parent,
  //     children: children
  //       .filter((child) => child.parentId === parent.id)
  //       .sort((a, b) => a.sortOrder - b.sortOrder), // Sort children based on sortOrder,
  //   };
  // });

  let structuredMenu = nestedMenuItems?.map((d) => ({
    name: d.name,
    active: true,
    icon: "table",
    children: d.children.map((s) => ({
      name: s.name,
      to: s.url,
      exact: true,
      active: true,
    })),
  }));

  // structuredMenu.push(
  //   {
  //     name: "Calender",
  //     icon: "table",
  //     to: "/calender",
  //     exact: true,
  //     active: true,
  //   },
  //   {
  //     name: "Timeline",
  //     icon: "table",
  //     to: "/timeline",
  //     exact: true,
  //     active: true,
  //   }
  // );

  // console.log(structuredMenu);

  // const fetchMenu = async () => {
  //   try {
  //     await MenusAPI.get().then((res) => setMenuState(res?.data?.data));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchPermissions = async () => {
  //   await RolePermissionsAPI.get({ params: { roleId: 1 } }).then((res) =>
  //     setPermissionsData(res?.data?.data)
  //   );
  // };

  // useEffect(() => {
  //   fetchMenu();
  //   fetchPermissions();
  // }, []);

  // console.log(menuState, permissionsData);

  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
    },
  } = useAppContext();

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    } else {
      HTMLClassList.remove("navbar-vertical-collapsed");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove("navbar-vertical-collapsed-hover");
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      variant="light"
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" textClass="text-primary" width={70} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === "vibrant"
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : "none",
        }}
      >
        <div className="navbar-vertical-content scrollbar">
          {/* <Nav className="flex-column" as="ul">
            {routes.map((route) => (
              <Fragment key={route.label}>
                {!route.labelDisable && (
                  <NavbarLabel label={capitalize(route.label)} />
                )}
                <NavbarVerticalMenu routes={route.children} />
              </Fragment>
            ))}
          </Nav> */}

          <Nav className="flex-column" as="ul" key="">
            <Fragment>
              {/* {isLoading && <Spinner variant="primary" animation="border" />} */}
              {/* {!route.labelDisable && <NavbarLabel label={route.name} />} */}
              <NavbarVerticalMenu routes={structuredMenu} />
            </Fragment>
          </Nav>

          <>
            {navbarPosition === "combo" && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
            {/* <PurchaseCard /> */}
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string,
};

export default NavbarVertical;
