import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchMarkets = () => {
  return LibraryAPI.get({ params: { library: 8 } });
};
export const useFetchMarkets = () => {
  return useQuery({
    queryKey: ["markets"],
    queryFn: fetchMarkets,
  });
};
