import { MenusAPI, RolePermissionsAPI } from "api";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { FormProvider, useFormContext } from "react-hook-form";

import Accordion from "react-bootstrap/Accordion";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { useFetchMenus } from "hooks/useFetchMenu";
import { customSettings } from "utils/customConfig";

const AssignmentBasicInformation = () => {
  const { register } = useFormContext();
  const location = useLocation();
  const navigate = useNavigate();

  const menuState = JSON.parse(localStorage.getItem("menus"));

  // const [menuState, setMenuState] = useState([]);
  const [collapseState, setCollapseState] = useState(false);
  const [modulePermissions, setModulePermissions] = useState([]);

  // const { data: menuState, isLoading: isLoadingMenus } = useFetchMenus();

  // ============================For react query=============================

  // const parents = menuState?.data.data.filter((item) => item.parentId === 0);
  // const children = menuState?.data.data.filter((item) => item.parentId !== 0);
  // ============================For react query END=============================

  const parents = menuState?.filter((item) => item.parentId === 0);
  const children = menuState?.filter((item) => item.parentId !== 0);

  const sortedParents = parents?.sort((a, b) => a.sortOrder - b.sortOrder);
  // Map children to their respective parents
  const nestedData = sortedParents?.map((parent) => {
    return {
      ...parent,
      children: children
        .filter((child) => child.parentId === parent.id)
        .sort((a, b) => a.sortOrder - b.sortOrder), // Sort children based on sortOrder,
    };
  });

  // Map children to their respective parents
  // const nestedData = parents.map((parent) => {
  //   return {
  //     ...parent,
  //     children: children.filter((child) => child.parentId === parent.id),
  //   };
  // });

  let structuredMenu = nestedData?.map((d) => ({
    name: d.name,
    active: true,
    icon: "table",
    children: d.children,
  }));

  // Function to handle permission change for a module
  const handlePermissionChange = (moduleId, permissionType) => (event) => {
    const isChecked = event.target.checked;

    // Check if there's an existing permission object for the module
    const existingPermission = modulePermissions.find(
      (permission) => permission.menuId === moduleId
    );
    if (existingPermission) {
      // Update the existing permission object
      const updatedPermission = {
        ...existingPermission,
        [permissionType]: isChecked,
      };
      setModulePermissions((prevPermissions) =>
        prevPermissions.map((permission) =>
          permission.menuId === moduleId ? updatedPermission : permission
        )
      );
    } else {
      // Create a new permission object
      const newPermission = {
        roleId: location?.state ? location?.state?.id : 0,
        menuId: moduleId,
        allowView: false,
        allowDelete: false,
        allowUpdate: false,
        allowNew: false,
        [permissionType]: isChecked,
      };
      setModulePermissions((prevPermissions) => [
        ...prevPermissions,
        newPermission,
      ]);
    }
  };

  const handleSubmit = async (e) => {
    try {
      await RolePermissionsAPI.post(modulePermissions);
      toast.success("Record updated successfully", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      navigate(-1);
    } catch (error) {
      toast.error("Something went wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  const fetchPermissions = async () => {
    if (location.state) {
      const response = await RolePermissionsAPI.get({
        params: { roleId: location.state.id },
      });
      setModulePermissions(response.data.data);
    }
  };

  useEffect(() => {
    // fetchMenu();
    fetchPermissions();
  }, []);

  // if (isLoadingMenus) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  // console.log(structuredMenu);

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        {/* Basic information */}
        <div className="row ">
          <div className="col">Module Name</div>
          <div className="col">Feature</div>
          <div className="col d-flex gap-5">
            <div>View</div>
            <div>Add</div>
            <div>Edit</div>
            <div>Delete</div>
          </div>
        </div>
        {/* <Table responsive>
          <thead>
            <tr>
              <th scope="col" colSpan={3}>
                Module Name
              </th>
              <th scope="col">Feature</th>
              <th scope="col">View</th>
              <th scope="col">Add</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
        </Table> */}
      </Card.Header>
      <Card.Body>
        {structuredMenu.map((data, index) => (
          <Accordion>
            <Accordion.Item eventKey={index} className="h-1">
              <Accordion.Header>{data.name}</Accordion.Header>
              <Accordion.Body>
                <div className="py-2">
                  {data.children.map((menu) => (
                    // console.log(menu),
                    <>
                      <div className="row " key={menu.id}>
                        <h6 className="col"></h6>
                        <h6 className="col">{menu.name}</h6>

                        <h6 className="col d-flex gap-5">
                          <Form.Check
                            type="checkbox"
                            id="defaultCheckbox"
                            label=""
                            name="allowView"
                            onChange={handlePermissionChange(
                              menu.id,
                              "allowView"
                            )}
                            checked={
                              modulePermissions.find((d) => d.menuId == menu.id)
                                ?.allowView || false
                            }

                            // onChange={(e) => handlePermissions(e, menu)}
                          />
                          <Form.Check
                            type="checkbox"
                            id="defaultCheckbox"
                            label=""
                            // onChange={(e) => handlePermissions(e, menu)}
                            onChange={handlePermissionChange(
                              menu.id,
                              "allowNew"
                            )}
                            name="allowNew"
                            checked={
                              modulePermissions.find((d) => d.menuId == menu.id)
                                ?.allowNew || false
                            }
                          />
                          <Form.Check
                            type="checkbox"
                            id="defaultCheckbox"
                            label=""
                            // onChange={(e) => handlePermissions(e, menu)}
                            onChange={handlePermissionChange(
                              menu.id,
                              "allowUpdate"
                            )}
                            name="allowUpdate"
                            checked={
                              modulePermissions.find((d) => d.menuId == menu.id)
                                ?.allowUpdate || false
                            }
                          />
                          <Form.Check
                            type="checkbox"
                            id="defaultCheckbox"
                            label=""
                            // onChange={(e) => handlePermissions(e, menu)}
                            onChange={handlePermissionChange(
                              menu.id,
                              "allowDelete"
                            )}
                            name="allowDelete"
                            checked={
                              modulePermissions.find((d) => d.menuId == menu.id)
                                ?.allowDelete || false
                            }
                          />
                        </h6>
                      </div>
                    </>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
        {/* {menuState
          .filter((el) => el.parentId === 0)
          .map((data) => (
            <Accordion>
              <Accordion.Item eventKey={data.id} className="h-1">
                <Accordion.Header>{data.name}</Accordion.Header>
                <Accordion.Body>
                  <div className="py-2">
                    {menuState
                      .filter((el) => el.parentId !== 0)
                      .map((menu) => (
                        <>
                          <div className="row " key={menu.id}>
                            <h6 className="col"></h6>
                            <h6 className="col">{menu.name}</h6>

                            <h6 className="col d-flex gap-5">
                              <Form.Check
                                type="checkbox"
                                id="defaultCheckbox"
                                label=""
                                name="allowView"
                                onChange={handlePermissionChange(
                                  menu.id,
                                  "allowView"
                                )}
                                checked={
                                  modulePermissions.find(
                                    (d) => d.menuId == menu.id
                                  )?.allowView
                                }

                                // onChange={(e) => handlePermissions(e, menu)}
                             
                              />
                              <Form.Check
                                type="checkbox"
                                id="defaultCheckbox"
                                label=""
                                // onChange={(e) => handlePermissions(e, menu)}
                                onChange={handlePermissionChange(
                                  menu.id,
                                  "allowNew"
                                )}
                                name="allowNew"
                                checked={
                                  modulePermissions.find(
                                    (d) => d.menuId == menu.id
                                  )?.allowNew
                                }
                         
                              />
                              <Form.Check
                                type="checkbox"
                                id="defaultCheckbox"
                                label=""
                                // onChange={(e) => handlePermissions(e, menu)}
                                onChange={handlePermissionChange(
                                  menu.id,
                                  "allowUpdate"
                                )}
                                name="allowUpdate"
                                checked={
                                  modulePermissions.find(
                                    (d) => d.menuId == menu.id
                                  )?.allowUpdate
                                }
                          
                              />
                              <Form.Check
                                type="checkbox"
                                id="defaultCheckbox"
                                label=""
                                // onChange={(e) => handlePermissions(e, menu)}
                                onChange={handlePermissionChange(
                                  menu.id,
                                  "allowDelete"
                                )}
                                name="allowDelete"
                                checked={
                                  modulePermissions.find(
                                    (d) => d.menuId == menu.id
                                  )?.allowDelete
                                }
                           
                              />
                            </h6>
                          </div>
                        </>
                      ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))} */}
        <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              {/* {update ? "Update" : "Save"} */}
              Ok
            </Button>
          </Col>
        </Row>

        {/* <div>
          <Button
            onClick={() => setCollapseState(!collapseState)}
            aria-controls="example-collapse-text"
            aria-expanded={collapseState}
            variant="falcon-default"
            className="mb-2  w-100 d-flex"
          >
            {menuState.find((f) => f.parentId == 0)?.name}
          </Button>

          <Collapse in={collapseState}>
            <div className="border rounded ">
              <div className="py-2">
                {menuState
                  .filter((el) => el.parentId !== 0)
                  .map((menu) => (
                    <div className="row ">
                      <h6 className="col"></h6>
                      <h6 className="col">{menu.name}</h6>
                      <h6 className="col d-flex gap-5">
                        <Form.Check
                          type="checkbox"
                          id="defaultCheckbox"
                          label=""
                        />
                        <Form.Check
                          type="checkbox"
                          id="defaultCheckbox"
                          label=""
                        />
                        <Form.Check
                          type="checkbox"
                          id="defaultCheckbox"
                          label=""
                        />
                        <Form.Check
                          type="checkbox"
                          id="defaultCheckbox"
                          label=""
                        />
                      </h6>
                    </div>
                  ))}
              </div>
            </div>
          </Collapse>
        </div> */}
      </Card.Body>
    </Card>
  );
};

export default AssignmentBasicInformation;
