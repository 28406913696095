import { yupResolver } from "@hookform/resolvers/yup";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import AddDriverBasicInformation from "./AddDriverBasicInformation";
import AddDriverFooter from "./AddDriverFooter";
import AddDriverHeader from "./AddDriverHeader";

const schema = yup
  .object({
    name: yup.string().required("This field is required."),
    father: yup.string().required("This field is required."),
    CNIC: yup.string().required("This field is required."),
    age: yup.string().required("This field is required."),
    profilePicture: yup.string().required("This field is required."),
    phone: yup.string().required("This field is required."),
    mobile: yup.string().required("This field is required."),
    email: yup.string().required("This field is required."),
    designation: yup.string().required("This field is required."),
    gender: yup.string().required("This field is required."),
    dob: yup.string().required("This field is required."),
    address: yup.string().required("This field is required."),
    country: yup.string().required("This field is required."),
    state: yup.string().required("This field is required."),
    city: yup.string().required("This field is required."),
    zip: yup.string().required("This field is required."),
    accountTitle: yup.string().required("This field is required."),
    number: yup.string().required("This field is required."),
    bankName: yup.string().required("This field is required."),
    branchCode: yup.string().required("This field is required."),
    class: yup.string().required("This field is required."),
    expectedSalary: yup.string().required("This field is required."),
    shift: yup.string().required("This field is required."),
  })
  .required();

const AddDriver = () => {
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = (data) => {
    console.log(data);
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);
    for (const key of keys) {
      submittedValues[key] = "";
    }
    reset({ ...submittedValues });
  };

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <AddDriverHeader />
            </Col>
            <Col lg={12}>
              <AddDriverBasicInformation />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
            {/* <Col xs={12}>
              <AddAuthoritiesFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddDriver;
