import { LibraryAPI } from "api";
import { useFetchAngles } from "hooks/useFetchAngles";
import { useFetchCategories } from "hooks/useFetchCategories";
import { useFetchCities } from "hooks/useFetchCities";
import { useFetchDistances } from "hooks/useFetchDistances";
import { useFetchMarkets } from "hooks/useFetchMarkets";
import { useFetchRegions } from "hooks/useFetchRegions";
import { useFetchSpaceAddresses } from "hooks/useFetchSpaceAddresses";
import { useFetchSpaceClasses } from "hooks/useFetchSpaceClasses";
import { useFetchSpaceScores } from "hooks/useFetchSpaceScores";
import { useFetchSpaceTypes } from "hooks/useFetchSpaceTypes";
import { useFetchStatuses } from "hooks/useFetchStatuses";
import { useFetchVisibilities } from "hooks/useFetchVisibilities";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const InventoryBasicInformation = () => {
  const {
    register,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const formId = useQuery().get("id");

  let regionValue = watch("region");
  let cityValue = watch("city");

  const {
    data: spaceTypes,
    isLoading: isLoadingSpaceTypes,
    isFetching: isFetchingSpaceTypes,
  } = useFetchSpaceTypes();
  const {
    data: spaceAddresses,
    isLoading: isLoadingSpaceAddresses,
    isFetching: isFetchingSpaceAddresses,
  } = useFetchSpaceAddresses();
  const {
    data: categories,
    isLoading: isLoadingCategories,
    isFetching: isFetchingCategories,
  } = useFetchCategories();
  const {
    data: statuses,
    isLoading: isLoadingStatuses,
    isFetching: isFetchingStatuses,
  } = useFetchStatuses();
  const {
    data: regions,
    isLoading: isLoadingRegions,
    isFetching: isFetchingRegions,
  } = useFetchRegions();
  const {
    data: marketAreas,
    isLoading: isLoadingMarkets,
    isFetching: isFetchingMarkets,
  } = useFetchMarkets();
  const {
    data: visibilities,
    isLoading: isLoadingVisibilities,
    isFetching: isFetchingVisibilities,
  } = useFetchVisibilities();
  const {
    data: spaceClasses,
    isLoading: isLoadingSpaceClasses,
    isFetching: isFetchingSpaceClasses,
  } = useFetchSpaceClasses();
  const {
    data: spaceScores,
    isLoading: isLoadingSpaceScores,
    isFetching: isFetchingSpaceScores,
  } = useFetchSpaceScores();
  const {
    data: distances,
    isLoading: isLoadingDistances,
    isFetching: isFetchingDistances,
  } = useFetchDistances();
  const {
    data: angles,
    isLoading: isLoadingAngles,
    isFetching: isFetchingAngles,
  } = useFetchAngles();
  const {
    data: cities,
    isLoading: isLoadingCities,
    isFetching: isFetchingCities,
  } = useFetchCities();

  return (
    <>
      <Card.Header as="h6" className="bg-body-tertiary mb-2">
        Basic information
      </Card.Header>
      <Row className="gx-2 gy-3">
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Type">
              <Form.Select
                aria-label="spaceType"
                autoFocus
                isInvalid={!!errors.spaceType}
                {...register("spaceType")}
              >
                <option value="">Select</option>
                {spaceTypes?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.spaceType?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Space Id"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.spaceId}
                {...register("spaceId")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.spaceId?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Description"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.description}
                {...register("description")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Category">
              <Form.Select
                aria-label="category"
                isInvalid={!!errors.category}
                {...register("category")}
              >
                <option value="">Select</option>
                {categories?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.category?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Authority">
              <Form.Select
                aria-label="authority"
                isInvalid={!!errors.authority}
                {...register("authority")}
              >
                <option value="">Select</option>

                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.authority?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Address">
              <Form.Select
                aria-label="spaceAddress"
                isInvalid={!!errors.spaceAddress}
                {...register("spaceAddress")}
              >
                <option value="">Select</option>
                {spaceAddresses?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.spaceAddress?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Size">
              <Form.Select
                aria-label="spaceSize"
                isInvalid={!!errors.spaceSize}
                {...register("spaceSize")}
              >
                <option value="">Select</option>

                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.spaceSize?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Side">
              <Form.Select
                aria-label="side"
                isInvalid={!!errors.side}
                {...register("side")}
              >
                <option value="">Select</option>
                <option value="A">A</option>
                <option value="B">B</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.side?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel
              controlId="floatingSelectGrid"
              label="Ownership Type"
            >
              <Form.Select
                aria-label="ownershipType"
                isInvalid={!!errors.ownershipType}
                {...register("ownershipType")}
              >
                <option value="">Select</option>
                <option value="own">Own</option>
                <option value="rental">Rental</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.ownershipType?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Status">
              <Form.Select
                aria-label="status"
                isInvalid={!!errors.status}
                {...register("status")}
              >
                <option value="">Select</option>
                {statuses?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.status?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Acquired Date"
              className=""
            >
              <Form.Control
                type="date"
                placeholder=""
                isInvalid={!!errors.acquiredDate}
                {...register("acquiredDate")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.acquiredDate?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Available Dates"
              className=""
            >
              <Form.Control
                type="date"
                placeholder=""
                isInvalid={!!errors.availableDates}
                {...register("availableDates")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.availableDates?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Card.Header as="h6" className="bg-body-tertiary mb-2">
          Space Location
        </Card.Header>

        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Region">
              <Form.Select
                aria-label="region"
                isInvalid={!!errors.region}
                {...register("region")}
              >
                <option value="">Select</option>
                {regions?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.region?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="City">
              <Form.Select
                aria-label="city"
                isInvalid={!!errors.city}
                disabled={!regionValue}
                {...register("city")}
              >
                <option value="">Select</option>
                {cities?.data.data
                  .filter((r) => r.parentId1 === Number(regionValue))
                  .map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.city?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Market / Area">
              <Form.Select
                aria-label="area"
                isInvalid={!!errors.area}
                disabled={!cityValue}
                {...register("area")}
              >
                <option value="">Select</option>
                {marketAreas?.data.data
                  .filter((r) => r.parentId2 === Number(cityValue))
                  .map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.area?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Latitude"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.latitude}
                {...register("latitude")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.latitude?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Longitude"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.longitude}
                {...register("longitude")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.longitude?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Google Map"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.map}
                {...register("map")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.map?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Card.Header as="h6" className="bg-body-tertiary mb-2">
          Features
        </Card.Header>

        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Visibility">
              <Form.Select
                aria-label="visibility"
                isInvalid={!!errors.visibility}
                {...register("visibility")}
              >
                <option value="">Select</option>
                {visibilities?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.visibility?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Lights"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.lights}
                {...register("lights")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lights?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Strata"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.strata}
                {...register("strata")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.strata?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Class">
              <Form.Select
                aria-label="spaceClass"
                isInvalid={!!errors.spaceClass}
                {...register("spaceClass")}
              >
                <option value="">Select</option>
                {spaceClasses?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.spaceClass?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Score">
              <Form.Select
                aria-label="spaceScore"
                isInvalid={!!errors.spaceScore}
                {...register("spaceScore")}
              >
                <option value="">Select</option>
                {spaceScores?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.type}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.spaceScore?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Traffic Speed"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.trafficSpeed}
                {...register("trafficSpeed")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.trafficSpeed?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Traffic Count"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.trafficCount}
                {...register("trafficCount")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.trafficCount?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Height"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors.height}
                {...register("height")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.height?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Angle">
              <Form.Select
                aria-label="angle"
                isInvalid={!!errors.angle}
                {...register("angle")}
              >
                <option value="">Select</option>
                {angles?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.type}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.angle?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Distance">
              <Form.Select
                aria-label="distance"
                isInvalid={!!errors.distance}
                {...register("distance")}
              >
                <option value="">Select</option>
                {distances?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.type}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.distance?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Col md="12">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Pictures</Form.Label>
            <Form.Control
              type="file"
              size="lg"
              isInvalid={!!errors.logo}
              {...register("logo")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.logo?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="12">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Videos</Form.Label>
            <Form.Control
              type="file"
              size="lg"
              isInvalid={!!errors.logo}
              {...register("logo")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.logo?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {/* <Row className="gx-2 gy-3 my-1">
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            className="text-secondary fw-medium p-0 me-3"
            type="button"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button variant="primary" onClick={() => setKey("costing")}>
            Next
          </Button>
        </Col>
      </Row> */}
    </>
  );
};

export default InventoryBasicInformation;
