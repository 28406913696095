import { useFetchRx } from "hooks/useFetchRx";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useDetectKeyboardLanguage from "utils/useDetectKeyboardLanguage";

const MedicationBasicInformation = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();

  let instructionWatch = watch("name");
  const layout = useDetectKeyboardLanguage(instructionWatch);

  const {
    data: rx,
    isLoading: isRxLoading,
    isFetching: isRxFetching,
  } = useFetchRx();

  let rxData = rx?.data?.data ?? [];

  if (isRxLoading) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Type <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  autoFocus
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.type}
                  {...register("type")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label={
                  <span>
                    Rx <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Form.Select
                  aria-label="rx"
                  isInvalid={!!errors.rx}
                  {...register("rx")}
                >
                  <option value="">Select</option>
                  {rxData.map((d) => (
                    <option value={d.id}>{d.name}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.rx?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Instructions <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  style={{
                    direction: layout === "Urdu" ? "rtl" : "ltr",
                  }}
                  placeholder="name@example.com"
                  autoComplete="off"
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Role">
                <Form.Select
                  aria-label="role"
                  isInvalid={!!errors.role}
                  {...register("role")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.role?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
        <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Ok
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MedicationBasicInformation;
