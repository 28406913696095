import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SchoolBasicInformation from "./SchoolBasicInformation";
import UserHeader from "./SchoolHeader";
import SchoolFooter from "./SchoolFooter";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import SchoolHeader from "./SchoolHeader";
import { LibraryAPI } from "api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { customSettings } from "utils/customConfig";

const schema = yup
  .object({
    type: yup.string().required("This field is required."),
    name: yup.string().required("This field is required."),
    level: yup.string().required("This field is required."),
    strength: yup.string().required("This field is required."),
    locationAddress: yup.string().required("This field is required."),
    active: yup.boolean().required("This field is required."),
    // email: yup.string().required("This field is required."),
    // password: yup.string().required("This field is required."),
    // role: yup.string().required("This field is required."),
  })
  .required();

const AddSchool = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: location.state
      ? {
          type: location.state.type || "",
          name: location.state.name || "",
          level: location.state.level || "",
          strength: location.state.strength || "",
          locationAddress: location.state.parentId1 || "",
          active: location.state.active || false,
        }
      : {},
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = { ...data };

    {
      myData.id = location?.state ? location?.state?.id : 0;
      myData.library = 23;
      myData.parentId1 = data?.locationAddress;
      myData.phone = "";
      myData.email = "";
      myData.logo = "";
    }

    console.log(myData);

    try {
      await LibraryAPI.post(myData);
      toast.success("Posted", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (location.state) {
  //     reset(location.state);
  //   }
  // }, []);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <SchoolHeader />
            </Col>
            <Col lg={12}>
              <SchoolBasicInformation />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
            {/* <Col xs={12}>
              <SchoolFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddSchool;
