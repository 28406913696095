import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import InventoryBasicInformation from "./InventoryBasicInformation";

import BreadCrumb from "components/breadcrumb/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import CostingInformation from "./CostingInformation";
import InventoryHeader from "./InventoryHeader";
import { customSettings } from "utils/customConfig";

const schema = yup
  .object({
    spaceType: yup.string().required("This field is required."),
    spaceId: yup.string().required("This field is required."),
    description: yup.string().required("This field is required."),
    category: yup.string().required("This field is required."),
    authority: yup.string().required("This field is required."),
    spaceAddress: yup.string().required("This field is required."),
    spaceSize: yup.string().required("This field is required."),
    side: yup.string().required("This field is required."),
    ownershipType: yup.string().required("This field is required."),
    status: yup.string().required("This field is required."),
    acquiredDate: yup.string().required("This field is required."),
    region: yup.string().required("This field is required."),
    city: yup.string().required("This field is required."),
    area: yup.string().required("This field is required."),
    latitude: yup.string().required("This field is required."),
    longitude: yup.string().required("This field is required."),
    map: yup.string().required("This field is required."),
    visibility: yup.string().required("This field is required."),
    lights: yup.string().required("This field is required."),
    strata: yup.string().required("This field is required."),
    spaceClass: yup.string().required("This field is required."),
    spaceScore: yup.string().required("This field is required."),
    trafficSpeed: yup.string().required("This field is required."),
    trafficCount: yup.string().required("This field is required."),
    height: yup.string().required("This field is required."),
    angle: yup.string().required("This field is required."),
    distance: yup.string().required("This field is required."),
    designType: yup.string().required("This field is required."),
    vendor: yup.string().required("This field is required."),
    price: yup.string().required("This field is required."),
    materialType: yup.string().required("This field is required."),
    size: yup.string().required("This field is required."),
    workType: yup.string().required("This field is required."),
    pricePerNight: yup.string().required("This field is required."),
    // active: yup.boolean().required("This field is required."),
    // userName: yup.string().required("This field is required."),
    // email: yup.string().required("This field is required."),
    // password: yup.string().required("This field is required."),
    // role: yup.string().required("This field is required."),
  })
  .required();

const AddInventory = () => {
  const [key, setKey] = useState("basicInfo");
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = { ...data };
    // {
    //   myData.id = location?.state ? location?.state?.id : 0;
    //   myData.library = 0;
    //   myData.phone = "Phone";
    //   myData.email = "Email";
    //   myData.logo = "Logo";
    // }

    // console.log(data);
    console.log(myData);

    // try {
    //   await LibraryAPI.post(myData);
    //   toast.success("Posted", { position: customSettings.toastPosition,
    // autoClose: customSettings.toastClose, });

    //   // ------- Get all object keys form data and set empty values to reset ------------
    //   const keys = Object.keys(data);
    //   for (const key of keys) {
    //     submittedValues[key] = "";
    //   }
    //   reset({ ...submittedValues });
    //   navigate(-1);
    // } catch (error) {
    //   toast.error("Something Wrong", {
    //    position: customSettings.toastPosition,
    // autoClose: customSettings.toastClose,
    //   });
    //   console.log(error);
    // }
  };

  useEffect(() => {
    if (location.state) {
      reset(location.state);
    }
  }, []);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <InventoryHeader />
            </Col>
            <Col lg={12}>
              <Card className="mb-3 py-2">
                {/* <Card.Header>
                  <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">
                    Inventory
                  </h5>
                </Card.Header> */}
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                >
                  <Tab eventKey="basicInfo" title="Basic Info" className="p-3 ">
                    <InventoryBasicInformation />
                  </Tab>
                  <Tab eventKey="costing" title="Costing" className=" p-3">
                    <CostingInformation />
                  </Tab>
                </Tabs>
                <hr />
                <Row className="gx-2 gy-3 my-1 px-3">
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="link"
                      className="text-secondary fw-medium p-0 me-3"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Ok
                    </Button>
                  </Col>
                </Row>
              </Card>

              {/* <CategoryBasicInformation /> */}
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddInventory;
