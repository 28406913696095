import { API_BASE } from "./Base";

export { API_BASE };

export const MenusAPI = new API_BASE("/Core/Menus");
export const LibraryAPI = new API_BASE("/Core/Library");
export const LibraryByParentIdAPI = new API_BASE("/Core/LibraryByParentId");
export const ContactAPI = new API_BASE("/Core/Contact");
export const ContactsAPI = new API_BASE("/Core/Contacts");
export const AppointmentsAPI = new API_BASE("/Core/Appointments");
export const AppointmentsByIDsAPI = new API_BASE("/Core/AppointmentsByIDs");
export const AppointmentsByDateAPI = new API_BASE("/Core/AppointmentsByDate");
export const RolesAPI = new API_BASE("/Administration/Roles");
export const RoleAPI = new API_BASE("/Administration/Role");
export const UpdateProfileAPI = new API_BASE("/Administration/UpdateProfile");
export const RolePermissionsAPI = new API_BASE(
  "/Administration/RolePermissions"
);
export const ForgetPasswordAPI = new API_BASE("/Authentication/Forgot");
