import { LibraryAPI } from "api";
import { useFetchCities } from "hooks/useFetchCities";
import { useFetchMarkets } from "hooks/useFetchMarkets";
import { useFetchRegions } from "hooks/useFetchRegions";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SpaceAddressBasicInformation = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  let regionValue = watch("region");
  let cityValue = watch("city");
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    data: cities,
    isFetching: isFetchingCities,
    isLoading: isLoadingCities,
  } = useFetchCities();

  const {
    data: regions,
    isFetching: isFetchingRegions,
    isLoading: isLoadingRegions,
  } = useFetchRegions();

  const {
    data: markets,
    isFetching: isFetchingMarkets,
    isLoading: isLoadingMarkets,
  } = useFetchMarkets();

  if (
    isFetchingCities ||
    isFetchingRegions ||
    isFetchingMarkets ||
    isLoadingCities ||
    isLoadingMarkets ||
    isLoadingRegions
  ) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Type"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoFocus
                  isInvalid={!!errors.type}
                  {...register("type")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Region">
                <Form.Select
                  aria-label="region"
                  isInvalid={!!errors.region}
                  {...register("region")}
                >
                  <option value="">Select</option>
                  {regions?.data.data.map((r) => (
                    <option value={r.id}>{r.name}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.region?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  disabled={!regionValue}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  {cities?.data.data
                    .filter((r) => r.parentId1 === Number(regionValue))
                    .map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Market">
                <Form.Select
                  aria-label="market"
                  isInvalid={!!errors.market}
                  disabled={!cityValue}
                  {...register("market")}
                >
                  <option value="">Select</option>
                  {markets?.data.data
                    .filter((r) => r.parentId2 === Number(cityValue))
                    .map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.market?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Check
              type="switch"
              id="defaultSwitch"
              label="Active"
              defaultChecked={!state}
              isInvalid={!!errors.active}
              {...register("active")}
            />
          </Col>
          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.email}
                  {...register("email")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  isInvalid={!!errors.userName}
                  {...register("userName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Role">
                <Form.Select
                  aria-label="role"
                  isInvalid={!!errors.role}
                  {...register("role")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.role?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
        <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Ok
              {/* Save */}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SpaceAddressBasicInformation;
