import { Checkbox } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import CardDropdown from "components/common/CardDropdown";
import IconButton from "components/common/IconButton";
import TableHeaderDropdown from "components/common/TableHeaderDropdown";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Card, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { CONSTANT_ROUTES } from "routes";
import "./styles.css";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "./utils/StoreState";
import { AppointmentsByIDsAPI } from "api";
import { useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";

export default function AppointmentsTable() {
  let isDarkBool = localStorage.getItem("isDark") === true;
  let isDarkTheme = localStorage.getItem("theme") === "dark";
  let auto = localStorage.getItem("theme") === "auto";
  const navigate = useNavigate();
  const columnApiRef = useRef();
  const location = useLocation();
  const gridRef = useRef();
  // let gridApi;
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [selectedItems, setSelectedItems] = useState([]);
  const [columnCustom, setColumnCustom] = useState([]);
  const [theme, setTheme] = useState();
  // const [gridApi, setGridApi] = useState();
  // const [columnApi, setColumnApi] = useState();

  const [rowData, setRowData] = useState([
    // { id: "1", type: "ABC", name: "Model Y" },
  ]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    // { field: "", filter: false, headerName: "Select" },

    // {
    //   field: "type",
    //   headerName: "Type",

    //   hide: false,
    // },
    {
      field: "appointmentAt",
      headerName: "Date & Time",
      hide: false,
      cellRenderer: (v) =>
        format(parseISO(v.data.appointmentAt), "yyyy-MM-dd HH:mm:ss"),
    },
    { field: "physicianName", headerName: "Physician Name", hide: false },
    { field: "instructions", headerName: "Instructions", hide: false },
    // {
    //   field: "active",
    //   headerName: "Active",
    //   hide: false,
    //   cellStyle: {
    //     display: "flex",
    //     paddingRight: "30px",
    //     justifyContent: "center",
    //   },
    //   headerClass: "center-header",
    //   cellRenderer: (p) => <Checkbox checked={p?.data.active} />,
    // },
    // {
    //   headerName: "Action",
    //   field: "action",
    //   maxWidth: 120,
    //   filter: false,
    //   cellRenderer: (p) => (
    //     <CardDropdown>
    //       <div className="py-2">
    //         <Dropdown.Item
    //           // as={Link}
    //           onClick={() =>
    //             // navigate(`/libraries/categories/add-new-category/?id=${p.data.id}`)
    //             // () =>
    //             handleRowClick(p.data)
    //           }
    //           // href="/products/add-product"
    //           className="d-flex align-items-center gap-2 py-0"
    //         >
    //           <MdOutlineModeEdit />
    //           Edit
    //         </Dropdown.Item>
    //         {/* <Dropdown.Item
    //           // onClick={() => handleDelete()}
    //           className="d-flex align-items-center gap-2 py-0"
    //         >
    //           <MdDeleteOutline />
    //           Delete
    //         </Dropdown.Item> */}
    //       </div>
    //     </CardDropdown>
    //   ),
    // },
  ]);

  const handleRowClick = (data) => {
    if (data) {
      navigate(CONSTANT_ROUTES.ADD_NEW_APPOINTMENT, { state: data });
    }
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    // floatingFilter: true,
    flex: 1,
    // rowDrag: true,
  };

  //   ===============Custom Api Call start================

  let gridApi;
  let columnApi;

  const onGridReady = useCallback(
    (e) => {
      gridApi = e.api;
      columnApi = e.columnApi;
      restoreState();
      AppointmentsByIDsAPI.get({
        params: { physicianId: null, patientId: location?.state?.id },
      }).then((res) => {
        console.log(res?.data.data);
        e.api.applyTransaction({ add: res?.data?.data });
      });
    },
    [window]
  );

  const saveState = useCallback(() => {
    const colState = columnApiRef.current.api.getColumnState();
    setLocalStorage(colState);
    console.log("column state saved");
  }, []);

  const restoreState = useCallback(() => {
    const colState = getLocalStorage();
    if (!colState) {
      console.log("no columns state to restore by, you must save state first");
      return;
    }
    columnApiRef.current.api.applyColumnState({
      state: colState,
      applyOrder: true,
    });
    console.log("column state restored");
  }, []);

  const resetState = useCallback(() => {
    columnApiRef.current.api.resetColumnState();
    clearLocalStorage();
    console.log("column state reset");
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    columnApiRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  // useEffect(() => {
  //   let isDarkBool = localStorage.getItem("isDark") === true;
  //   let isDarkTheme = localStorage.getItem("theme") === "dark";
  //   let auto = localStorage.getItem("theme") === "auto";

  //   if (isDarkBool || isDarkTheme || auto) {
  //     setTheme("ag-theme-quartz-dark");
  //   } else {
  //     setTheme("ag-theme-quartz");
  //   }
  // }, [localStorage.getItem("isDark") || localStorage.getItem("theme")]);

  // console.log(theme);

  return (
    <div>
      <div
        className={`${
          isDarkBool || isDarkTheme || auto
            ? "ag-theme-quartz-dark"
            : "ag-theme-quartz"
        } rounded-0`} // applying the grid theme
        style={{ height: 500 }} // the grid will fill the size of the parent container
      >
        <Card className="h-100 shadow-none">
          <Card.Header className="d-flex justify-content-between align-items-center  bg-body-tertiary mb-2">
            <h6 className="fs-10 mb-0 text-nowrap py-2 py-xl-0 ">
              Appointments
            </h6>
            <div className="d-flex gap-2">
              {/* <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: "fixed" }}
                    id="overlay-trigger-example"
                  >
                    New
                  </Tooltip>
                }
              >
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() => navigate(CONSTANT_ROUTES.ADD_NEW_APPOINTMENT)}
                >
                  <span className="d-none d-sm-inline-block ms-1">New</span>
                </IconButton>
              </OverlayTrigger> */}
              <TableHeaderDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    onClick={saveState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Save State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={restoreState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Restore State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={resetState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Reset State
                  </Dropdown.Item>
                </div>
              </TableHeaderDropdown>
            </div>
            {/* <div className="test-header">
              <div className="example-header">
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextBoxChanged}
                />
              </div>
              <div className="example-section">
                <button onClick={saveState}>Save State</button>
                <button onClick={restoreState}>Restore State</button>
                <button onClick={resetState}>Reset State</button>
              </div>
            </div> */}
          </Card.Header>

          <Card.Body className="p-0">
            {/* <button onClick={() => onExportClick()}>abc</button> */}

            <AgGridReact
              //   rowData={rowData}
              ref={columnApiRef}
              onGridReady={onGridReady}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              // rowStyle={{ cursor: "pointer" }}
              // pagination={true}
              // onRowDoubleClicked={(params) => handleRowClick(params.data)}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
