import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchSpaceTypes = () => {
  return LibraryAPI.get({ params: { library: 7 } });
};
export const useFetchSpaceTypes = () => {
  return useQuery({
    queryKey: ["space-types"],
    queryFn: fetchSpaceTypes,
  });
};
