import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

let typeOptions = [
  "Infectious disease",
  "Chronic diseases",
  "Genetic disorders",
  "Autoimmune diseases",
  "Mental health conditions",
  "Neurological Disorders",
  "Gastrointestinal Disorders",
  "Renal and Urological Disorders",
  "Musculoskeletal Disorders",
];

const options = [
  { value: "Infectious disease", label: "Infectious disease" },
  { value: "Chronic diseases", label: "Chronic diseases" },
  { value: "Genetic disorders", label: "Genetic disorders" },
  { value: "Autoimmune diseases", label: "Autoimmune diseases" },
  { value: "Mental health conditions", label: "Mental health conditions" },
  { value: "Neurological Disorders", label: "Neurological Disorders" },
  { value: "Gastrointestinal Disorders", label: "Gastrointestinal Disorders" },
  {
    value: "Renal and Urological Disorders",
    label: "Renal and Urological Disorders",
  },
  { value: "Musculoskeletal Disorders", label: "Musculoskeletal Disorders" },
];

const MedicalProblemBasicInformation = () => {
  const {
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();

  // const [typeValue, setTypeValue] = useState(state?.type);

  // useEffect(() => {
  //   setValue("type", typeValue);
  // }, [typeValue, setValue]);

  // const handleInputChange = (event) => {
  //   setTypeValue(event.target.value);
  // };

  useEffect(() => {
    register("type", { required: true });
  }, [register]);

  useEffect(() => {
    if (state) {
      setValue("type", state.type);
    }
  }, [state, setValue]);

  const handleSelectChange = (selectedOption) => {
    setValue("type", selectedOption ? selectedOption.value : "");
    if (selectedOption) {
      clearErrors("type");
    }
  };

  const handleInputFocus = () => {
    clearErrors("type");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: errors.type ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: errors.type ? "red" : provided["&:hover"].borderColor,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black", // Set the text color of the selected value to black
      padding: "7px",
    }),
    placeholder: (provided) => ({
      ...provided,
      padding: "7px",
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "7px",
      paddingRight: "7px",
      // padding: "12px", // Set the input text color to black
    }),

    menu: (provided) => ({
      ...provided,
      color: "black",
      zIndex: 9999, // Adjust the zIndex as needed
    }),
  };

  let initialSelectedOption = options.find(
    (option) => option.value === state?.type
  );

  if (initialSelectedOption == undefined) {
    initialSelectedOption =
      state && state.type
        ? { label: state?.type, value: state?.type }
        : undefined;
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              {/* <FloatingLabel controlId="floatingSelectGrid" label="Type">
                <Form.Select
                  aria-label="type"
                  autoFocus
                  isInvalid={!!errors.type}
                  {...register("type")}
                >
                  <option value="">Select</option>
                  <option value="Infectious disease">Infectious disease</option>
                  <option value="Chronic diseases">Chronic diseases</option>
                  <option value="Genetic disorders">Genetic disorders</option>
                  <option value="Autoimmune diseases">
                    Autoimmune diseases
                  </option>
                  <option value="Mental health conditions">
                    DMental health conditions
                  </option>
                  <option value="Neurological Disorders">
                    Neurological Disorders
                  </option>
                  <option value="Gastrointestinal Disorders">
                    Gastrointestinal Disorders
                  </option>
                  <option value="Renal and Urological Disorders">
                    Renal and Urological Disorders
                  </option>
                  <option value="Musculoskeletal Disorders">
                    Musculoskeletal Disorders
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
              </FloatingLabel> */}

              <CreatableSelect
                options={options}
                isClearable
                placeholder={
                  <span>
                    Type{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>*</span>
                  </span>
                }
                className={`${errors.type ? "is-invalid" : ""}`}
                styles={customStyles}
                onChange={handleSelectChange}
                onFocus={handleInputFocus}
                onInputChange={handleInputFocus}
                defaultValue={initialSelectedOption}
              />
              <Form.Control.Feedback type="invalid">
                {errors.type?.message}
              </Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Type"
                className=""
              >
                <Form.Control
                  type="text"
                  list="options"
                  autoFocus
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.type}
                  {...register("type")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
                <datalist id="options">
                  {typeOptions.map((d) => (
                    <option key={d} value={d} />
                  ))}
                </datalist>
              </FloatingLabel>
            </Form.Group> */}
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Name <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="name@example.com"
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Start Date <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="date"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.startDate}
                  {...register("startDate")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.startDate?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    End Date <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="date"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.endDate}
                  {...register("endDate")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.endDate?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Role">
                <Form.Select
                  aria-label="role"
                  isInvalid={!!errors.role}
                  {...register("role")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.role?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
        <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Ok
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MedicalProblemBasicInformation;
