import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchAngles = () => {
  return LibraryAPI.get({ params: { library: 15 } });
};
export const useFetchAngles = () => {
  return useQuery({
    queryKey: ["angles"],
    queryFn: fetchAngles,
  });
};
