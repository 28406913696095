import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchStatuses = () => {
  return LibraryAPI.get({ params: { library: 10 } });
};
export const useFetchStatuses = () => {
  return useQuery({
    queryKey: ["statuses"],
    queryFn: fetchStatuses,
  });
};
