import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconCloseButton from "components/common/FalconCloseButton";
import React, { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFilterAndSortMenuItems } from "utils/menuUtils";

const flattenMenuItems = (menuItems) => {
  let flatItems = [];
  menuItems?.forEach((item) => {
    flatItems.push(item);
    if (item.children) {
      flatItems = flatItems.concat(flattenMenuItems(item.children));
    }
  });
  return flatItems;
};

const SearchBoxCustom = ({ autoCompleteItem }) => {
  const { nestedMenuItems, isLoading } = useFilterAndSortMenuItems();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [resultItem, setResultItem] = useState(autoCompleteItem);

  const [query, setQuery] = useState("");
  const history = useNavigate();
  const allMenuItems = flattenMenuItems(nestedMenuItems);

  const filterParent = allMenuItems?.filter((i) => i.parentId !== 0);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleSelect = (url) => {
    setQuery("");
    setDropdownOpen(false);
    history(url);
  };

  const filteredItems = filterParent?.filter((item) =>
    item.name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <>
      {/* <div className="search-box">
        <Form className="position-relative">
          <Form.Control
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={query}
            onChange={handleSearch}
          />
        </Form>

        {query && (
          <ul
            style={{
              listStyle: "none",
              margin: 0,
              padding: 0,
              position: "absolute",
              width: "300px",
              maxHeight: "200px",
              overflowY: "auto",
              backgroundColor: "white",
              border: "1px solid #ccc",
              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            {filteredItems.map((item, index) => (
              <li
                key={index}
                onClick={() => handleSelect(item.url)}
                style={{
                  padding: "8px",
                  cursor: "pointer",
                }}
              >
                {item.name}
              </li>
            ))}
            {filteredItems.length === 0 && (
              <li style={{ padding: "8px", color: "#aaa" }}>
                No results found
              </li>
            )}
          </ul>
        )}
      </div> */}

      <Dropdown
        show={dropdownOpen}
        className="search-box"
        onToggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <Dropdown.Toggle as="div" className="dropdown-caret-none">
          <Form className="position-relative">
            <Form.Control
              type="search"
              placeholder="Search..."
              aria-label="Search"
              className="rounded-pill search-input"
              value={query}
              onChange={handleSearch}
            />
            <FontAwesomeIcon
              icon="search"
              className="position-absolute text-400 search-box-icon"
            />
            {query && (
              <div className="search-box-close-btn-container">
                <FalconCloseButton
                  size="sm"
                  noOutline
                  className="fs-11"
                  onClick={() => {
                    setQuery("");
                    setDropdownOpen(false);
                  }}
                />
              </div>
            )}
          </Form>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="scrollbar py-3" style={{ maxHeight: "24rem" }}>
            {query ? (
              <>
                <Dropdown.Header as="h6" className="px-x1 pt-0 fw-medium">
                  {filteredItems.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelect(item.url)}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      // className={`${
                      //   item.parentId === 0 ? "text-black fw-bold px-0" : ""
                      // }`}
                    >
                      {item.name}
                    </li>
                  ))}
                  {filteredItems.length === 0 && (
                    <li style={{ padding: "8px", color: "#aaa" }}>
                      No results found
                    </li>
                  )}
                </Dropdown.Header>
              </>
            ) : (
              <>
                <Dropdown.Header as="h6" className="px-x1 pt-0 fw-medium">
                  {filteredItems.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handleSelect(item.url)}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                      }}
                      // aria-disabled={item.parentId === 0}
                      // className={`${
                      //   item.parentId === 0 ? "text-black fw-bold px-0" : ""
                      // }`}
                    >
                      {item.name}
                    </li>
                  ))}
                </Dropdown.Header>
              </>
            )}
            {/* {query && (
              <ul
                style={{
                  listStyle: "none",
                  margin: 0,
                  padding: 0,
                  position: "absolute",
                  width: "300px",
                  maxHeight: "200px",
                  overflowY: "auto",
                  backgroundColor: "white",
                  border: "1px solid #ccc",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
                }}
              >
                {filteredItems.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelect(item.url)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                    }}
                  >
                    {item.name}
                  </li>
                ))}
                {filteredItems.length === 0 && (
                  <li style={{ padding: "8px", color: "#aaa" }}>
                    No results found
                  </li>
                )}
              </ul>
            )} */}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SearchBoxCustom;
