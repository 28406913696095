import { useAppContext } from "Main";
import React, { useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import cloudUpload from "assets/img/icons/cloud-upload.svg";
import classNames from "classnames";
import CardDropdown from "components/common/CardDropdown";
import Flex from "components/common/Flex";
import { getSize } from "helpers/utils";
import { useForm } from "react-hook-form";

import pdfThumb from "assets/img/icons/pdf.png";
import docIcon from "assets/img/icons/docx_icon.png";

const schema = yup
  .object({
    uploadedFiles: yup.array().required("This field is required."),
  })
  .required();

const UploadFilesModal = ({ setIsOpenModal, isOpenModal }) => {
  const {
    config: { isDark },
  } = useAppContext();

  const [formData, setFormData] = useState({});

  const handleClose = () => {
    setIsOpenModal(!isOpenModal);
  };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     console.log(formData);
  //   };

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png", ".jpg", ".pdf", ".docx"],
    },
    onDrop: (acceptedFiles) => {
      if (errors.uploadedFiles) {
        clearErrors("uploadedFiles");
      }
      setValue("uploadedFiles", acceptedFiles);
      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const handleRemove = (path) => {
    const filteredFiles = files.filter((file) => file.path !== path);
    setFiles(filteredFiles);
    setValue("uploadedFiles", filteredFiles.length ? filteredFiles : null);
  };

  const onSubmit = (data) => {
    console.log(data);
    setFiles([]);
    handleClose();
  };

  return (
    <>
      <Modal
        show={isOpenModal}
        onHide={handleClose}
        contentClassName="border"
        size="lg"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? "white" : undefined}
            className="bg-body-tertiary px-x1 border-bottom-0"
          >
            <Modal.Title as="h5">Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-x1">
            <div
              {...getRootProps({
                className: `${classNames("dropzone-area py-6", {
                  "is-invalid": errors.uploadedFiles,
                })}`,
              })}
            >
              <input name="uploadedFiles" {...getInputProps()} />
              <Flex justifyContent="center">
                <img src={cloudUpload} alt="" width={25} className="me-2" />
                <p className="fs-9 mb-0 text-700">Drop your images here</p>
              </Flex>
            </div>
            <div className="invalid-feedback">
              {errors.uploadedFiles?.message}
            </div>
            <div>
              {files.map((file) => (
                <Flex
                  alignItems="center"
                  className="py-3 border-bottom btn-reveal-trigger"
                  key={file.path}
                >
                  {file.type === "application/pdf" ? (
                    <img
                      src={pdfThumb}
                      width={40}
                      height={40}
                      className="rounded"
                      alt={file.path}
                    />
                  ) : file.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    file.type === "application/msword" ? (
                    <img
                      src={docIcon}
                      width={40}
                      height={40}
                      className="rounded"
                      alt={file.path}
                    />
                  ) : (
                    <img
                      className="rounded"
                      width={40}
                      height={40}
                      src={file.preview}
                      alt={file.path}
                    />
                  )}

                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className="ms-3 flex-1"
                  >
                    <div>
                      <h6>{file.path}</h6>
                      <Flex className="position-relative" alignItems="center">
                        <p className="mb-0 fs-10 text-400 line-height-1">
                          <strong>{getSize(file.size)}</strong>
                        </p>
                      </Flex>
                    </div>
                  </Flex>
                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleRemove(file.path)}
                      >
                        Remove
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </Flex>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
            <Button variant="primary" type="submit" className="px-4 mx-0">
              Ok
            </Button>
          </Modal.Footer>
        </Form>
        {/* <Form onSubmit={handleSubmit}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? "white" : undefined}
            className="bg-body-tertiary px-x1 border-bottom-0"
          >
            <Modal.Title as="h5">Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-x1"></Modal.Body>
          <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit}
              className="px-4 mx-0"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form> */}
      </Modal>
    </>
  );
};

export default UploadFilesModal;
