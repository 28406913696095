import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchWorkType = () => {
  return LibraryAPI.get({ params: { library: 19 } });
};
export const useFetchWorkType = () => {
  return useQuery({
    queryKey: ["work-Type"],
    queryFn: fetchWorkType,
  });
};
