import { useFetchCities } from "hooks/useFetchCities";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AddClinicContactBasicInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();
  const formId = useQuery().get("id");

  // const {
  //   data: citiesData,
  //   isFetching: isFetchingCities,
  //   isLoading: isLoadingCities,
  // } = useFetchCities();

  // if (isLoadingCities) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Name <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoFocus
                  autoComplete="off"
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Phone <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="phone"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.phone}
                  {...register("phone")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Mobile <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="phone"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.mobile}
                  {...register("mobile")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mobile?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Email <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="email"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.email}
                  {...register("email")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Designation <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.designation}
                  {...register("designation")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.designation?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          {/* <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Gender">
                <Form.Select
                  aria-label="gender"
                  isInvalid={!!errors.gender}
                  {...register("gender")}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.gender?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
          {/* <Col md="6">
            <Form.Group>
    
              <FloatingLabel
                controlId="floatingInput"
                label="Date of Birth"
                className=""
              >
                <Form.Control
                  type="date"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.dob}
                  {...register("dob")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dob?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Address <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.address}
                  {...register("address")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label={
                  <span>
                    City <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  <option value="Lahore">Lahore</option>
                  {/* {citiesData?.data.data.map((d) => (
                    <option value={d.id}>{d.name}</option>
                  ))} */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Zip Code <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="number"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.zipCode}
                  {...register("zipCode")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zipCode?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    State <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.state}
                  {...register("state")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.state?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Country <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.country}
                  {...register("country")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.country?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>

          {/* <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/*   <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  isInvalid={!!errors.userName}
                  {...register("userName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
        <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              OK
              {/* Save */}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AddClinicContactBasicInformation;
