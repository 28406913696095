import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchCities = () => {
  return LibraryAPI.get({ params: { library: 20 } });
};
export const useFetchCities = () => {
  return useQuery({
    queryKey: ["cities"],
    queryFn: fetchCities,
  });
};
