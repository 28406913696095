import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchMediumMaterialTypes = () => {
  return LibraryAPI.get({ params: { library: 18 } });
};
export const useFetchMediumMaterialTypes = () => {
  return useQuery({
    queryKey: ["cities"],
    queryFn: fetchMediumMaterialTypes,
  });
};
