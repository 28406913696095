import { useQuery } from "@tanstack/react-query";
import { ContactsAPI, LibraryAPI } from "api";

const fetchPhysicians = (entityId) => {
  return ContactsAPI.get({ params: { entityId } });
};
export const useFetchPhysicians = (entityId) => {
  return useQuery({
    queryKey: ["physician", entityId],
    queryFn: () => fetchPhysicians(entityId),
  });
};
