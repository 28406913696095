import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { useFilterAndSortMenuItems } from "utils/menuUtils";

export default function BreadCrumb() {
  // const location = useLocation();
  // const paths = location.pathname.split("/").filter((path) => path !== "");
  // return (
  //   <div>
  //     <Link to="/">Home</Link>
  //     {paths.map((path, index) => (
  //       <span key={index}>
  //         {" / "}
  //         <Link to={`/${paths.slice(0, index + 1).join("/")}`}>
  //           {path.charAt(0).toUpperCase() + path.slice(1)}
  //         </Link>
  //       </span>
  //     ))}
  //   </div>
  // );
  const breadcrumbs = useReactRouterBreadcrumbs();
  const maxLevel = 2;

  const filteredBreadcrumbs = breadcrumbs.filter(
    (el, index) => index !== 1 && index < maxLevel + 1
  );

  return (
    <React.Fragment>
      {filteredBreadcrumbs.map(({ breadcrumb, match }, i) => (
        <React.Fragment key={i}>
          <NavLink to={match.pathname}>{breadcrumb}</NavLink>
          {i !== filteredBreadcrumbs.length - 1 && " > "}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
