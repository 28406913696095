import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AddGroupBasicInformation from "./AddGroupBasicInformation";
import UserHeader from "./AddGroupHeader";
import AddGroupFooter from "./AddGroupFooter";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import AddGroupHeader from "./AddGroupHeader";
import useQuery from "hooks/useQuery";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    name: yup.string().required("This field is required."),
    // userName: yup.string().required("This field is required."),
    // email: yup.string().required("This field is required."),
    // password: yup.string().required("This field is required."),
    // role: yup.string().required("This field is required."),
  })
  .required();

const AddGroup = () => {
  const navigate = useNavigate();
  const formId = useQuery().get("id");

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = (data) => {
    console.log(data);
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);
    for (const key of keys) {
      submittedValues[key] = "";
    }
    reset({ ...submittedValues });
  };

  // const onSubmit = (formData) => {
  //   // setData(formData);
  //   localStorage.setItem("category", JSON.stringify(formData));
  //   toast.success("Data Save Successfully!");
  //   navigate(-1);
  //   reset({ ...formData }); // Reset the form after successful submission
  // };

  // useEffect(() => {
  //   if (formId) {
  //     let values = localStorage.getItem("category");
  //     let parseValue = JSON.parse(values);
  //     // console.log(getData);
  //     reset({ name: parseValue.name, type: parseValue.type });
  //   }
  // }, [formId]);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <AddGroupHeader />
            </Col>
            <Col lg={12}>
              <AddGroupBasicInformation />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
            {/* <Col xs={12}>
              <CategoryFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddGroup;
