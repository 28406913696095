import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AddVenueBasicInformation from "./AddVenueBasicInformation";
import UserHeader from "./AddVenueHeader";
import AddVenueFooter from "./AddVenueFooter";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import AddVenueHeader from "./AddVenueHeader";

const schema = yup
  .object({
    type: yup.string().required("This field is required."),
    name: yup.string().required("This field is required."),
    capacity: yup.string().required("This field is required."),
    availableDates: yup.string().required("This field is required."),
    ownerShipType: yup.string().required("This field is required."),
    location: yup.string().required("This field is required."),
    locationAddress: yup.string().required("This field is required."),
    active: yup.boolean().required("This field is required."),
    // userName: yup.string().required("This field is required."),
    // email: yup.string().required("This field is required."),
    // password: yup.string().required("This field is required."),
    // role: yup.string().required("This field is required."),
  })
  .required();

const AddVenue = () => {
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = (data) => {
    console.log(data);
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);
    for (const key of keys) {
      submittedValues[key] = "";
    }
    reset({ ...submittedValues });
  };

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <AddVenueHeader />
            </Col>
            <Col lg={12}>
              <AddVenueBasicInformation />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
            {/* <Col xs={12}>
              <AddVenueFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddVenue;
