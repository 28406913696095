import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import ContactsInformation from "./ContactsInformation";
import { useNavigate } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { useLocation } from "react-router-dom";

const AddUserBasicInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();
  const formId = useQuery().get("id");

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (formId) {
      setUpdate(true);
    }
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Name <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autocomplete="off"
                  autoFocus
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Login ID (Email Address){" "}
                    <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autocomplete="off"
                  isInvalid={!!errors.loginId}
                  {...register("loginId")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.loginId?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Password <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder=""
                  autocomplete="off"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Confirm Password <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder=""
                  autocomplete="off"
                  isInvalid={!!errors.confirmPassword}
                  {...register("confirmPassword")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Designation <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autocomplete="off"
                  isInvalid={!!errors.designation}
                  {...register("designation")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.designation?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label={
                  <span>
                    Group <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Form.Select
                  aria-label="group"
                  isInvalid={!!errors.group}
                  {...register("group")}
                >
                  <option value="">Select</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.group?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label={
                  <span>
                    Role <span style={{ color: "red" }}>*</span>
                  </span>
                }
              >
                <Form.Select
                  aria-label="role"
                  isInvalid={!!errors.role}
                  {...register("role")}
                >
                  <option value="">Select</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.role?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Check
              type="switch"
              id="defaultSwitch"
              label="Active"
              defaultChecked={!state}
              isInvalid={!!errors.active}
              {...register("active")}
            />
          </Col>
          {/* <Col md="12">
            <Form.Check
              type="switch"
              id="defaultSwitch"
              label="Active"
              defaultChecked={!state}
              isInvalid={!!errors.active}
              {...register("active")}
            />
          </Col> */}
        </Row>
      </Card.Body>
      <ContactsInformation />

      <Row className="gx-2 gy-3 px-3 mb-3 ">
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            className="text-secondary fw-medium p-0 me-3"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {update ? "Update" : "Save"}
            {/* Save */}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AddUserBasicInformation;
