import React, { useEffect, useMemo, useState } from "react";

export default function useDetectKeyboardLanguage(value) {
  const [layout, setLayout] = useState("");

  useEffect(() => {
    const detectUrduScript = (text) => {
      // Check if the text contains Urdu script characters
      const urduRegex = /[\u0600-\u06FF]/;
      const englishRegex = /^[A-Za-z0-9]*$/;

      if (urduRegex.test(text)) {
        setLayout("Urdu");
      } else if (englishRegex.test(text)) {
        setLayout("English");
      } else {
        setLayout("Unknown");
      }
    };
    detectUrduScript(value);
  }, [value]);

  return layout;
}
