import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "Main";
import { ContactAPI } from "api";
import React from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import * as yup from "yup";

const schema = yup
  .object({
    name: yup.string().required("This field is required."),
    phone: yup.string().required("This field is required."),
    mobile: yup.string().required("This field is required."),
    email: yup.string().required("This field is required."),
    gender: yup.string().required("This field is required."),
    age: yup.string().required("This field is required."),
    address: yup.string().required("This field is required."),
    country: yup.string().required("This field is required."),
    state: yup.string().required("This field is required."),
    city: yup.string(),
    zipCode: yup.string().required("This field is required."),
    active: yup.boolean(),
  })
  .required();

// const getCircleStackIcon = (icon, transform) => (
//   <span className="fa-stack ms-n1 me-3">
//     <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
//     <FontAwesomeIcon
//       icon={icon}
//       transform={transform ?? ""}
//       className="text-primary fa-stack-1x"
//       inverse
//     />
//   </span>
// );

// const EventModalMediaContent = ({ icon, heading, content, children }) => (
//   <Flex className="mt-3">
//     {getCircleStackIcon(icon)}
//     <div className="flex-1">
//       <h6>{heading}</h6>
//       {children || <p className="mb-0 text-justify">{content}</p>}
//     </div>
//   </Flex>
// );

const PatientAddModal = ({
  setIsOpenPatientModal,
  isOpenPatientModal,
  modalPatientContent,
}) => {
  const {
    config: { isDark },
  } = useAppContext();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let myData = { ...data };
    {
      myData.id = location?.state ? location?.state?.id : 0;
      myData.designation = "";
      myData.dob = "2024-06-06T10:55:43.879Z";
    }

    // console.log(myData);

    try {
      await ContactAPI.post(myData);
      toast.success("Record updated successfully", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      queryClient.invalidateQueries(["contacts"]); // Invalidate and refetch
      handleClose();
    } catch (error) {
      toast.error("Something went wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  const handleClose = () => {
    setIsOpenPatientModal(!isOpenPatientModal);
  };

  //   const { title, start, end } = isOpenPatientModal && modalPatientContent.event;
  //   const { instructions, patient, location, organizer, schedules } =
  //     isOpenPatientModal && modalPatientContent.event.extendedProps;

  return (
    <Modal
      show={isOpenPatientModal}
      onHide={handleClose}
      contentClassName="border"
      size="lg"

      //   centered
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? "white" : undefined}
            className="bg-body-tertiary px-x1 border-bottom-0"
          >
            <Modal.Title>
              <h5 className="mb-0">Patient</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-x1 pb-x1 pt-1 fs-10">
            <Row className="gx-2 gy-3">
              <Col md="6">
                <Form.Group>
                  {/* <Form.Label>First name:</Form.Label> */}
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Name"
                    className=""
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      autoFocus
                      isInvalid={!!errors.name}
                      {...register("name")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  {/* <Form.Label>First name:</Form.Label> */}
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Phone"
                    className=""
                  >
                    <Form.Control
                      type="phone"
                      placeholder=""
                      autoComplete="off"
                      isInvalid={!!errors.phone}
                      {...register("phone")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  {/* <Form.Label>First name:</Form.Label> */}
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Mobile"
                    className=""
                  >
                    <Form.Control
                      type="phone"
                      placeholder=""
                      autoComplete="off"
                      isInvalid={!!errors.mobile}
                      {...register("mobile")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mobile?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  {/* <Form.Label>First name:</Form.Label> */}
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className=""
                  >
                    <Form.Control
                      type="email"
                      placeholder=""
                      autoComplete="off"
                      isInvalid={!!errors.email}
                      {...register("email")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group>
                  <FloatingLabel controlId="floatingSelectGrid" label="Gender">
                    <Form.Select
                      aria-label="gender"
                      isInvalid={!!errors.gender}
                      {...register("gender")}
                    >
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.gender?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  {/* <Form.Label>First name:</Form.Label> */}
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Age"
                    className=""
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      isInvalid={!!errors.age}
                      {...register("age")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.age?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>

              <Col md="6">
                <Form.Group>
                  {/* <Form.Label>First name:</Form.Label> */}
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Address"
                    className=""
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      isInvalid={!!errors.address}
                      {...register("address")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  {/* <Form.Label>First name:</Form.Label> */}
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Country"
                    className=""
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      isInvalid={!!errors.country}
                      {...register("country")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.country?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  {/* <Form.Label>First name:</Form.Label> */}
                  <FloatingLabel
                    controlId="floatingInput"
                    label="State"
                    className=""
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      isInvalid={!!errors.state}
                      {...register("state")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <FloatingLabel controlId="floatingSelectGrid" label="City">
                    <Form.Select
                      aria-label="city"
                      isInvalid={!!errors.city}
                      {...register("city")}
                    >
                      <option value="">Select</option>
                      {/* {cities?.data.data.map((d) => (
                      <option value={d.id}>{d.name}</option>
                    ))} */}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.city?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  {/* <Form.Label>First name:</Form.Label> */}
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Postal Code"
                    className=""
                  >
                    <Form.Control
                      type="number"
                      placeholder=""
                      autoComplete="off"
                      isInvalid={!!errors.zipCode}
                      {...register("zipCode")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.zipCode?.message}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            {/* <Row className="gx-2 gy-3 my-1 ">
              <Col className="d-flex justify-content-end">
                <Button
                  variant="link"
                  className="text-secondary fw-medium p-0 me-3"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Ok
                </Button>
              </Col>
            </Row> */}
          </Modal.Body>
          <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
            <Button variant="primary" type="submit" className="px-4 mx-0">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

// EventModalMediaContent.propTypes = {
//   icon: PropTypes.string.isRequired,
//   heading: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   children: PropTypes.node,
// };

// CalendarEventModal.propTypes = {
//   setIsOpenEventModal: PropTypes.func.isRequired,
//   isOpenEventModal: PropTypes.bool.isRequired,
//   modalEventContent: PropTypes.object.isRequired,
// };

export default PatientAddModal;
