export const version = "4.4.0";
export const navbarBreakPoint = "xl"; // Vertical navbar breakpoint
export const topNavbarBreakpoint = "lg";
export const themeVariants = ["light", "dark", "auto"];
export const settings = {
  isFluid: false,
  isRTL: false,
  theme: "light",
  isDark: false,
  navbarPosition: "vertical",
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  isNavbarVerticalCollapsed: true, // toggle vertical navbar collapse
  navbarStyle: "transparent",
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
