import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import BreadCrumb from "components/breadcrumb/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import PatientHeader from "./PatientHeader";
import MedicalProblemsTable from "./medical-problems/MedicalProblemsTable";
import PatientBasicInformation from "./basic-information/PatientBasicInformation";
import AllergiesTable from "./allergies/AllergiesTable";
import MedicationsTable from "./medications/MedicationsTable";
import VisitsTable from "./visits/VisitsTable";
import HistoryTimeline from "./history/HistoryTimeline";
import LabReportsTable from "./lab-reports/LabReportsTable";
import VitalsTable from "./vitals/VitalsTable";
import AppointmentsTable from "./appointments/AppointmentsTable";
import GalleryView from "./lab-reports/GalleryView";

const AddPatient = () => {
  const [key, setKey] = useState("basicInfo");
  // const navigate = useNavigate();
  const location = useLocation();

  // const submittedValues = {};
  // const methods = useForm({
  //   resolver: yupResolver(schema),
  //   defaultValues: {},
  // });
  // const { handleSubmit, reset, setValue } = methods;

  // const onSubmit = async (data) => {
  //   let myData = { ...data };
  //   // {
  //   //   myData.id = location?.state ? location?.state?.id : 0;
  //   //   myData.library = 0;
  //   //   myData.phone = "Phone";
  //   //   myData.email = "Email";
  //   //   myData.logo = "Logo";
  //   // }

  //   // console.log(data);
  //   console.log(myData);

  //   // try {
  //   //   await LibraryAPI.post(myData);
  //   //   toast.success("Posted", { position: customSettings.toastPosition,
  //   // autoClose: customSettings.toastClose, });

  //   //   // ------- Get all object keys form data and set empty values to reset ------------
  //   //   const keys = Object.keys(data);
  //   //   for (const key of keys) {
  //   //     submittedValues[key] = "";
  //   //   }
  //   //   reset({ ...submittedValues });
  //   //   navigate(-1);
  //   // } catch (error) {
  //   //   toast.error("Something Wrong", {
  //   //    position: customSettings.toastPosition,
  //   // autoClose: customSettings.toastClose,
  //   //   });
  //   //   console.log(error);
  //   // }
  // };

  useLayoutEffect(() => {
    if (location.state && location.state?.tabIndex) {
      setKey(location.state?.tabIndex);
    }
  }, []);

  console.log(location.state);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <Row className="g-3">
        <Col xs={12}>
          <PatientHeader />
        </Col>
        <Col lg={12}>
          <Card className="mb-3 py-2">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="basicInfo" title="Basic Info" className="p-3">
                <PatientBasicInformation />
              </Tab>
              <Tab
                eventKey="medical-problems"
                title="Medical Problems"
                className="p-3"
              >
                <MedicalProblemsTable />
              </Tab>
              <Tab eventKey="allergies" title="Allergies" className="p-3">
                <AllergiesTable />
              </Tab>
              <Tab eventKey="medication" title="Medications" className="p-3">
                <MedicationsTable />
              </Tab>
              <Tab eventKey="visits" title="Visits" className="p-3">
                <VisitsTable />
              </Tab>
              <Tab eventKey="history" title="History" className="p-3">
                <HistoryTimeline />
              </Tab>
              <Tab eventKey="labReports" title="Lab Reports" className="p-3">
                {/* <LabReportsTable /> */}
                <GalleryView />
              </Tab>
              <Tab eventKey="vitals" title="Vitals" className="p-3">
                <VitalsTable />
              </Tab>
              <Tab eventKey="appointments" title="Appointments" className="p-3">
                <AppointmentsTable />
              </Tab>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddPatient;
