import { LibraryAPI } from "api";
import { useFetchCities } from "hooks/useFetchCities";
import { useFetchLocations } from "hooks/useFetchLocation";
import { useFetchRegions } from "hooks/useFetchRegions";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AddLocationAddressBasicInformation = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();
  const formId = useQuery().get("id");

  let regionValue = watch("region");

  const {
    data: cities,
    isFetching: isFetchingCities,
    isLoading: isLoadingCities,
  } = useFetchCities();
  const {
    data: locations,
    isFetching: isFetchingLocations,
    isLoading: isLoadingLocations,
  } = useFetchLocations();
  const {
    data: regions,
    isFetching: isFetchingRegions,
    isLoading: isLoadingRegions,
  } = useFetchRegions();

  if (
    isFetchingCities ||
    isFetchingLocations ||
    isFetchingRegions ||
    isLoadingCities ||
    isLoadingLocations ||
    isLoadingRegions
  ) {
    return <Spinner animation="border" variant="primary" />;
  }

  // const [cities, setCities] = useState([]);

  // const fetchCities = async () => {
  //   await LibraryAPI.get({ params: { library: 20 } }).then((res) =>
  //     setCities(res?.data.data)
  //   );
  // };

  // const [regions, setRegions] = useState([]);

  // const fetchRegions = async () => {
  //   await LibraryAPI.get({ params: { library: 6 } }).then((res) =>
  //     setRegions(res?.data.data)
  //   );
  // };

  // const [locations, setLocation] = useState([]);

  // const fetchLocations = async () => {
  //   await LibraryAPI.get({ params: { library: 21 } }).then((res) =>
  //     setLocation(res?.data.data)
  //   );
  // };

  // useEffect(() => {
  //   fetchCities();
  //   fetchRegions();
  //   fetchLocations();
  // }, []);

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Location">
                <Form.Select
                  aria-label="location"
                  autoFocus
                  isInvalid={!!errors.location}
                  {...register("location")}
                >
                  <option value="">Select</option>
                  {locations?.data.data.map((r) => (
                    <option key={r.id} value={Number(r.id)}>
                      {r.name}
                    </option>
                  ))}
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.location?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Address"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.address}
                  {...register("address")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Region">
                <Form.Select
                  aria-label="region"
                  isInvalid={!!errors.region}
                  {...register("region")}
                >
                  <option value="">Select</option>
                  {regions?.data.data.map((r) => (
                    <option key={r.id} value={Number(r.id)}>
                      {r.name}
                    </option>
                  ))}
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.region?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  disabled={!regionValue}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  {cities?.data.data
                    .filter((r) => r.parentId1 === Number(regionValue))
                    .map((d) => (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    ))}
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Latitude"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="322323232"
                  isInvalid={!!errors.latitude}
                  {...register("latitude")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.latitude?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Longitude"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="67454878"
                  isInvalid={!!errors.longitude}
                  {...register("longitude")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.longitude?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Strata"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.strata}
                  {...register("strata")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.strata?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Check
              type="switch"
              id="defaultSwitch"
              label="Active"
              defaultChecked={!state}
              isInvalid={!!errors.active}
              {...register("active")}
            />
          </Col>
          {/*   <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  isInvalid={!!errors.userName}
                  {...register("userName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
        <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Ok
              {/* Save */}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AddLocationAddressBasicInformation;
