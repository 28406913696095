import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchMaterialType = () => {
  return LibraryAPI.get({ params: { library: 18 } });
};
export const useFetchMaterialType = () => {
  return useQuery({
    queryKey: ["material-Type"],
    queryFn: fetchMaterialType,
  });
};
