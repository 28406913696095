import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchDistances = () => {
  return LibraryAPI.get({ params: { library: 16 } });
};
export const useFetchDistances = () => {
  return useQuery({
    queryKey: ["distances"],
    queryFn: fetchDistances,
  });
};
