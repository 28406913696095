import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const AddAttendanceBasicInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const formId = useQuery().get("id");

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (formId) {
      setUpdate(true);
    }
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Date"
                className=""
              >
                <Form.Control
                  type="date"
                  placeholder=""
                  isInvalid={!!errors.date}
                  {...register("date")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.date?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Pickup Time"
                className=""
              >
                <Form.Control
                  type="time"
                  placeholder=""
                  isInvalid={!!errors.pickupTime}
                  {...register("pickupTime")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.pickupTime?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Location">
                <Form.Select
                  aria-label="location"
                  isInvalid={!!errors.location}
                  {...register("location")}
                >
                  <option value="">Select</option>
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.location?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label="Vehicle Number"
              >
                <Form.Select
                  aria-label="vehicleNumber"
                  isInvalid={!!errors.vehicleNumber}
                  {...register("vehicleNumber")}
                >
                  <option value="">Select</option>
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.vehicleNumber?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Driver">
                <Form.Select
                  aria-label="driver"
                  isInvalid={!!errors.driver}
                  {...register("driver")}
                >
                  <option value="">Select</option>
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.driver?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {update ? "Update" : "Save"}
              {/* Save */}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AddAttendanceBasicInformation;
