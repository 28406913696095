import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchRxs = () => {
  return LibraryAPI.get({ params: { library: 1 } });
};
export const useFetchRx = () => {
  return useQuery({
    queryKey: ["rx"],
    queryFn: fetchRxs,
  });
};
