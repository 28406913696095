import React from "react";
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

function BankInformation() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Card.Header as="h6" className="bg-body-tertiary">
        Bank information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Account Title"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.accountTitle}
                  {...register("accountTitle")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.accountTitle?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Number"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.number}
                  {...register("number")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.number?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Bank Name"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.bankName}
                  {...register("bankName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.bankName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Branch Code"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.branchCode}
                  {...register("branchCode")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.branchCode?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </>
  );
}

export default BankInformation;
