import styled from "styled-components";
import "./styles.css";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import {
  Button,
  Card,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "./utils/StoreState";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import IconButton from "components/common/IconButton";
import { useNavigate } from "react-router-dom";
import CardDropdown from "components/common/CardDropdown";
import { MdDeleteOutline, MdOutlineModeEdit } from "react-icons/md";
import { CONSTANT_ROUTES } from "routes";
import { useLocation } from "react-router-dom";
import { ContactsAPI } from "api";

export default function ClientsContactsTable() {
  let isDarkBool = localStorage.getItem("isDark") === true;
  let isDarkTheme = localStorage.getItem("theme") === "dark";
  let auto = localStorage.getItem("theme") === "auto";
  const navigate = useNavigate();
  const location = useLocation();
  const columnApiRef = useRef();
  const gridRef = useRef();
  // let gridApi;
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [selectedItems, setSelectedItems] = useState([]);
  const [columnCustom, setColumnCustom] = useState([]);
  const [theme, setTheme] = useState();
  // const [gridApi, setGridApi] = useState();
  // const [columnApi, setColumnApi] = useState();

  const [rowData, setRowData] = useState([]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    // { field: "", filter: false, headerName: "Select" },

    {
      field: "name",
      headerName: "Name",
      hide: false,
      cellStyle: {
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    { field: "phone", headerName: "Phone", hide: false },
    {
      field: "mobile",
      headerName: "Mobile",
      hide: false,
      cellStyle: {
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      field: "email",
      headerName: "Email",
      hide: false,
      cellStyle: {
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    { field: "designation", headerName: "Designation", hide: false },
    { field: "gender", headerName: "Gender", hide: false },
    {
      field: "dob",
      headerName: "Date of Birth",
      hide: false,
      cellStyle: {
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      field: "address",
      headerName: "Address",
      hide: false,
      cellStyle: {
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    { field: "country", headerName: "Country", hide: false },
    { field: "state", headerName: "State", hide: false },
    { field: "city", headerName: "City", hide: false },
    { field: "zipCode", headerName: "Zip", hide: false },

    {
      headerName: "Action",
      field: "action",
      filter: false,
      cellRenderer: (p) => (
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item
              // as={Link}
              onClick={() => handleRowClick(p.data)}
              // href="/products/add-product"
              className="d-flex align-items-center gap-2 py-0"
            >
              <MdOutlineModeEdit />
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              //   onClick={() => handleDelete(row)}
              className="d-flex align-items-center gap-2 py-0"
            >
              <MdDeleteOutline />
              Delete
            </Dropdown.Item>
          </div>
        </CardDropdown>
      ),
    },
  ]);

  const defaultColDef = {
    sortable: true,
    filter: true,
    // floatingFilter: true,
    flex: 1,
    resizable: true,
    // rowDrag: true,
  };

  const handleAdd = () => {
    if (location.state) {
      navigate(CONSTANT_ROUTES.ADD_NEW_CLIENT_CONTACT, {
        state: { entityId: location.state.id },
      });
    } else {
      navigate(CONSTANT_ROUTES.ADD_NEW_CLIENT_CONTACT);
    }
  };

  const handleRowClick = (data) => {
    if (data) {
      navigate(CONSTANT_ROUTES.ADD_NEW_CLIENT_CONTACT, { state: data });
    }
  };

  //   ===============Custom Api Call start================
  let gridApi;
  let columnApi;

  const onGridReady = useCallback(
    (e) => {
      gridApi = e.api;
      columnApi = e.columnApi;

      restoreState();
      // ContactsAPI.get({
      //   params: {
      //     entityId: location?.state?.id,
      //   },
      // }).then((res) => {
      //   setRowData(res?.data?.data);
      // });

      ContactsAPI.get({ params: { entityId: location?.state?.id } }).then(
        (res) => {
          e.api.applyTransaction({
            add: res?.data?.data.filter((f) => f.entityId != 0),
          });
        }
      );
    },
    [window]
  );

  console.log(location.state);

  const saveState = useCallback(() => {
    const colState = columnApiRef.current.api.getColumnState();
    setLocalStorage(colState);
    console.log("column state saved");
  }, []);

  const restoreState = useCallback(() => {
    const colState = getLocalStorage();
    if (!colState) {
      console.log("no columns state to restore by, you must save state first");
      return;
    }
    columnApiRef.current.api.applyColumnState({
      state: colState,
      applyOrder: true,
    });
    console.log("column state restored");
  }, []);

  const resetState = useCallback(() => {
    columnApiRef.current.api.resetColumnState();
    clearLocalStorage();
    console.log("column state reset");
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    columnApiRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  // useEffect(() => {
  //   let isDarkBool = localStorage.getItem("isDark") === true;
  //   let isDarkTheme = localStorage.getItem("theme") === "dark";
  //   let auto = localStorage.getItem("theme") === "auto";

  //   if (isDarkBool || isDarkTheme || auto) {
  //     setTheme("ag-theme-quartz-dark");
  //   } else {
  //     setTheme("ag-theme-quartz");
  //   }
  // }, [localStorage.getItem("isDark") || localStorage.getItem("theme")]);

  // console.log(theme);

  return (
    <>
      {/* <div className="py-2">
        <BreadCrumb />
      </div> */}

      <div
        className={`${
          isDarkBool || isDarkTheme || auto
            ? "ag-theme-quartz-dark"
            : "ag-theme-quartz"
        }`} // applying the grid theme
        style={{ height: 500 }} // the grid will fill the size of the parent container
      >
        <Card className="shadow-none h-100 rounded-0">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Contacts</h5>
            <OverlayTrigger
              overlay={
                <Tooltip
                  style={{ position: "fixed" }}
                  id="overlay-trigger-example"
                >
                  New
                </Tooltip>
              }
            >
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                onClick={handleAdd}
              >
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </IconButton>
            </OverlayTrigger>
            {/* <div className="test-header">
              <div className="example-header">
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextBoxChanged}
                />
              </div>
              <div className="example-section">
                <button onClick={saveState}>Save State</button>
                <button onClick={restoreState}>Restore State</button>
                <button onClick={resetState}>Reset State</button>
              </div>
            </div> */}
          </Card.Header>

          <Card.Body className="p-0">
            {/* <button onClick={() => onExportClick()}>abc</button> */}

            <AgGridReact
              // rowData={rowData.filter((f) => f.entityId != 0)}
              ref={columnApiRef}
              onGridReady={onGridReady}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}

              // pagination={true}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
