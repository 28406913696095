import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import useQuery from "hooks/useQuery";
import ClinicsContactsTable from "../Clinics-contacts/ClinicsContactsTable";
import { useLocation } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

let typeOptions = [
  "Primary Care",
  "Urgent Care",
  "Specialized",
  "Mental Health",
  "Addiction Services",
  "Dialysis",
  "Laboratory",
];

const options = [
  { value: "Primary Care", label: "Primary Care" },
  { value: "Urgent Care", label: "Urgent Care" },
  { value: "Specialized", label: "Specialized" },
  { value: "Mental Health", label: "Topical Medications" },
  { value: "Addiction Services", label: "Addiction Services" },
  { value: "Dialysis", label: "Dialysis" },
  { value: "Laboratory", label: "Laboratory" },
];

const AddClinicBasicInformation = () => {
  const {
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const formId = useQuery().get("id");

  // const [typeValue, setTypeValue] = useState(state?.type);

  // useEffect(() => {
  //   setValue("type", typeValue);
  // }, [typeValue, setValue]);

  // const handleInputChange = (event) => {
  //   setTypeValue(event.target.value);
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    register("type", { required: true });
  }, [register]);

  useEffect(() => {
    if (state) {
      setValue("type", state.type);
    }
  }, [state, setValue]);

  const handleSelectChange = (selectedOption) => {
    setValue("type", selectedOption ? selectedOption.value : "");
    if (selectedOption) {
      clearErrors("type");
    }
  };

  const handleInputFocus = () => {
    clearErrors("type");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: errors.type ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: errors.type ? "red" : provided["&:hover"].borderColor,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black", // Set the text color of the selected value to black
      padding: "7px",
    }),
    placeholder: (provided) => ({
      ...provided,
      padding: "7px",
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "7px",
      paddingRight: "7px",
      // padding: "12px", // Set the input text color to black
    }),

    menu: (provided) => ({
      ...provided,
      color: "black",
      zIndex: 9999, // Adjust the zIndex as needed
    }),
  };

  let initialSelectedOption = options.find(
    (option) => option.value === state?.type
  );

  if (initialSelectedOption == undefined) {
    initialSelectedOption = state
      ? { label: state?.type, value: state?.type }
      : undefined;
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              {/* <FloatingLabel controlId="floatingSelectGrid" label="Type">
                <Form.Select
                  aria-label="type"
                  autoFocus
                  isInvalid={!!errors.type}
                  {...register("type")}
                >
                  <option value="">Select</option>
                  <option value="Primary Care">Primary Care</option>
                  <option value="Urgent Care">Urgent Care</option>
                  <option value="Specialized">Specialized</option>
                  <option value="Mental Health">Mental Health</option>
                  <option value="Addiction Services">Addiction Services</option>
                  <option value="Dialysis">Dialysis</option>
                  <option value="Laboratory">Laboratory</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
              </FloatingLabel> */}
              <CreatableSelect
                options={options}
                isClearable
                placeholder={
                  <span>
                    Type{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>*</span>
                  </span>
                }
                className={`${errors.type ? "is-invalid" : ""}`}
                styles={customStyles}
                onChange={handleSelectChange}
                onFocus={handleInputFocus}
                onInputChange={handleInputFocus}
                defaultValue={initialSelectedOption}
              />
              <Form.Control.Feedback type="invalid">
                {errors.type?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {/* <Col md="6">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Type"
                className=""
              >
                <Form.Control
                  type="text"
                  list="options"
                  placeholder=""
                  autoFocus
                  autoComplete="off"
                  // value={typeValue || ""}
                  isInvalid={!!errors.type}
                  {...register("type")}
                  // onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
                <datalist id="options">
                  {typeOptions.map((d) => (
                    <option key={d} value={d} />
                  ))}
                </datalist>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Name <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Phone <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="phone"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.phone}
                  {...register("phone")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Email <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="email"
                  placeholder=""
                  autoComplete="off"
                  isInvalid={!!errors.email}
                  {...register("email")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Logo</Form.Label>
              <Form.Control
                type="file"
                size="lg"
                isInvalid={!!errors.logo}
                {...register("logo")}
                onChange={handleImageChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.logo?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {image && (
            <div>
              <img src={image} alt="Preview" style={{ maxWidth: "300px" }} />
            </div>
          )}
          <Col md="12">
            <Form.Check
              type="switch"
              id="defaultSwitch"
              label="Active"
              defaultChecked={!state}
              isInvalid={!!errors.active}
              {...register("active")}
            />
          </Col>
        </Row>
      </Card.Body>
      {/* <hr /> */}
      {/* <ContactsInformation /> */}
      <Row className="gx-2 px-3 ">
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            className="text-secondary fw-medium p-0 me-3"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Ok
          </Button>
        </Col>
      </Row>
      <hr />
      <ClinicsContactsTable />
    </Card>
  );
};

export default AddClinicBasicInformation;
