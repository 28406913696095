import { yupResolver } from "@hookform/resolvers/yup";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import AddAttendanceBasicInformation from "./AddAttendanceBasicInformation";
import AddAttendanceHeader from "./AddAttendanceHeader";

const schema = yup
  .object({
    date: yup.string().required("This field is required."),
    pickupTime: yup.string().required("This field is required."),
    location: yup.string().required("This field is required."),
    vehicleNumber: yup.string().required("This field is required."),
    driver: yup.string().required("This field is required."),
  })
  .required();

const AddAttendance = () => {
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = (data) => {
    console.log(data);
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);
    for (const key of keys) {
      submittedValues[key] = "";
    }
    reset({ ...submittedValues });
  };

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <AddAttendanceHeader />
            </Col>
            <Col lg={12}>
              <AddAttendanceBasicInformation />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
            {/* <Col xs={12}>
              <AddCityFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddAttendance;
