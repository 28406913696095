import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchRegion = () => {
  return LibraryAPI.get({ params: { library: 6 } });
};
export const useFetchRegions = () => {
  return useQuery({
    queryKey: ["regions"],
    queryFn: fetchRegion,
  });
};
