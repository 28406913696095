import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useAppContext } from "Main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "components/common/IconButton";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import events from "data/calendar/events";
import AddScheduleModal from "./AddScheduleModal";
import CalendarEventModal from "./CalendarEventModal";
import DropdownFilter from "components/common/DropdownFilter";
import { useFetchAppointmentsByIDs } from "hooks/useFetchAppointments";
import "./index.css";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useFetchAppointmentsByDates } from "hooks/useFetchAppointmentsByDates";
import { format, isPast } from "date-fns";
import { disabledDates } from "./utils";

const AppointmentsCalender = () => {
  const {
    config: { isRTL },
  } = useAppContext();
  const calendarRef = useRef();
  const [title, setTitle] = useState("");
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState("Month View");
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(undefined);
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState({});
  const [scheduleDate, setScheduleDate] = useState();
  const [dateOfEvent, setDateOfEvent] = useState();

  const eventTimeFormat = {
    hour: "numeric",
    minute: "2-digit",
    omitZeroMinute: false,
    meridiem: true,
  };

  const handleEventClick = (info) => {
    // console.log(info);
    // if (info.event.url) {
    //   window.open(info.event.url);
    //   info.jsEvent.preventDefault();
    // } else {
    setModalEventContent(info);
    setIsOpenScheduleModal(true);
    // }
  };

  const [initialEvents, setInitialEvents] = useState([]);
  const [dates, setDates] = useState({});

  // const { data, isLoading, isFetching } = useFetchAppointmentsByIDs(109);

  const {
    data: appointmentsByDate,
    isLoading: loadingAppointmentsByDate,
    isFetching: fetchingAppointmentsByDate,
  } = useFetchAppointmentsByDates(
    dates?.startStr?.slice(0, 10),
    dates?.endStr?.slice(0, 10)
  );

  const viewName = [
    "Month View",
    "Week View",
    "Day View",
    "List View",
    // "Year View",
  ];

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // const eventsWithColors = events.map((event) => {
  //   const color = generateRandomColor();
  //   return {
  //     ...event,
  //     backgroundColor: color,
  //     borderColor: color,
  //     textColor: "#ffffff",
  //   };
  // });

  // useEffect(() => {
  //   setInitialEvents(eventsWithColors);
  // }, []);

  let usedIndexes = [];
  const colorClasses = [
    "bg-primary-subtle",
    "bg-success-subtle",
    "bg-info-subtle",
    "bg-warning-subtle",
    "bg-danger-subtle",
    // "bg-secondary-subtle",
    // "bg-dark-subtle",
    // "bg-black-subtle",
    // "bg-purple-subtle",
    // "bg-pink-subtle",
    // "bg-brown-subtle",
    // "bg-teal-subtle",
    // "bg-orange-subtle",
    // "bg-indigo-subtle",
    // "bg-maroon-subtle",
  ];

  const generateRandomClassName = () => {
    if (usedIndexes.length === colorClasses.length) {
      // If all indexes have been used, reset the usedIndexes array
      usedIndexes = [];
    }

    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * colorClasses.length);
    } while (usedIndexes.includes(randomIndex));

    usedIndexes.push(randomIndex);
    return colorClasses[randomIndex];
    // const randomIndex = Math.floor(Math.random() * colorClasses.length);
    // return colorClasses[randomIndex];
  };

  const transformAppointment = (appointment) => {
    const color = generateRandomClassName();
    let pastAppointment = isPast(appointment.appointmentAt);
    return {
      id: appointment.id,
      title: `${appointment.patientName}`,
      start: appointment.appointmentAt,
      appointmentTime: appointment.appointmentAt,
      description: appointment.instructions,
      status: appointment.status,
      physicianId: appointment.physicianId,
      patientId: appointment.patientId,
      classNames: pastAppointment ? "bg-secondary-subtle" : color,
      // classNames: color,
    };
  };

  useLayoutEffect(() => {
    if (appointmentsByDate) {
      const transformedEvents =
        appointmentsByDate?.data?.data.map(transformAppointment);
      setInitialEvents(transformedEvents);
    }
  }, [appointmentsByDate]);

  const handleEventContentForDisplay = (eventInfo) => {
    return (
      <div className="">
        <span className="d-flex align-items-center">
          <span className="px-2 ">{eventInfo.event.title} - </span>
          {eventInfo.timeText}
          {/* <FontAwesomeIcon icon={faPlus} className="plus-icon" /> */}
        </span>
      </div>
    );
  };

  const handleFilter = (filter) => {
    setCurrentFilter(filter);
    switch (filter) {
      case "Month View":
        calendarApi.changeView("dayGridMonth");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case "Week View":
        calendarApi.changeView("timeGridWeek");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case "Day View":
        calendarApi.changeView("timeGridDay");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case "List View":
        calendarApi.changeView("listWeek");
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView("listYear");
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
  }, []);

  const selectAllow = (info) => {
    const startDate = format(info.start, "yyyy-MM-dd");
    return !disabledDates.includes(startDate);
  };

  const dayCellDidMount = (info) => {
    // const plusIcon = document.createElement("div");
    // plusIcon.className = "plus-icon";
    // plusIcon.innerHTML = "+";
    // info.el.appendChild(plusIcon);
    const dateString = format(info.date, "yyyy-MM-dd");
    if (disabledDates.includes(dateString)) {
      info.el.classList.add("fc-disabled-date");
    }
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center gx-1">
            <Col xs="auto" className="order-md-0">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
            <Col xs="auto" className=" order-md-0">
              <h4 className="mb-0 fs-9 fs-sm-8 fs-lg-7">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs="auto" className="d-flex order-md-0">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: "fixed" }} id="nextTooltip">
                    Previous
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: "fixed" }} id="previousTooltip">
                    Next
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            {/* <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Today
              </Button>
            </Col> */}
            {/* <Col md="auto" className="d-md-none">
              <hr />
            </Col> */}
            <Col className="d-flex justify-content-end order-md-1">
              <IconButton
                variant="primary"
                iconClassName="me-2"
                icon="plus"
                // transform="shrink-3"
                size="sm"
                onClick={() => {
                  setIsOpenScheduleModal(!isOpenScheduleModal);
                }}
              >
                Add Appointment
              </IconButton>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={2}
            direction={isRTL ? "rtl" : "ltr"}
            height={"100vh"}
            stickyHeaderDates={false}
            editable
            selectable
            selectMirror
            select={(info) => {
              setIsOpenScheduleModal(true);
              setDateOfEvent(info.start);
              // setScheduleStartDate(info.start);
              // setScheduleEndDate(info.end);
            }}
            eventContent={handleEventContentForDisplay}
            eventTimeFormat={eventTimeFormat}
            eventClick={handleEventClick}
            events={initialEvents}
            datesSet={(info) => setDates(info)}
            selectAllow={selectAllow}
            dayCellDidMount={dayCellDidMount}
          />
        </Card.Body>
      </Card>

      <AddScheduleModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        initialEvents={initialEvents}
        setInitialEvents={setInitialEvents}
        modalEventContent={modalEventContent}
        setModalEventContent={setModalEventContent}
        setDateOfEvent={setDateOfEvent}
        dateOfEvent={dateOfEvent}
        // scheduleDate={scheduleDate}
        // scheduleEndDate={scheduleEndDate}
        // setScheduleDate={setScheduleDate}
        // setScheduleEndDate={setScheduleEndDate}
      />

      <CalendarEventModal
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
        modalEventContent={modalEventContent}
      />
    </>
  );
};

export default AppointmentsCalender;
