import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchClinics = () => {
  return LibraryAPI.get({ params: { library: 0 } });
};
export const useFetchClinics = () => {
  return useQuery({
    queryKey: ["clinics"],
    queryFn: fetchClinics,
  });
};
