import { yupResolver } from "@hookform/resolvers/yup";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import AddAuthorityContactBasicInformation from "./AddVendorContactBasicInformation";

import AddVendorContactHeader from "./AddVendorContactHeader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ContactAPI } from "api";
import { customSettings } from "utils/customConfig";

const schema = yup
  .object({
    name: yup.string().required("This field is required."),
    phone: yup.string().required("This field is required."),
    mobile: yup.string().required("This field is required."),
    email: yup.string().required("This field is required."),
    designation: yup.string().required("This field is required."),
    gender: yup.string().required("This field is required."),
    dob: yup.string().required("This field is required."),
    address: yup.string().required("This field is required."),
    country: yup.string().required("This field is required."),
    state: yup.string().required("This field is required."),
    city: yup.string().required("This field is required."),
    zipCode: yup.string().required("This field is required."),
    active: yup.boolean(),
  })
  .required();

const AddVendorContact = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = { ...data };
    {
      myData.entityId = location?.state && location?.state?.entityId;
      myData.id = location?.state ? location?.state?.id : 0;
    }

    console.log(myData);

    try {
      await ContactAPI.post(myData);
      toast.success("Posted", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      reset(location.state);
    }
  }, []);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <AddVendorContactHeader />
            </Col>
            <Col lg={12}>
              <AddAuthorityContactBasicInformation />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
            {/* <Col xs={12}>
              <AddContactFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddVendorContact;
