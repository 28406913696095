import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchSpaceScores = () => {
  return LibraryAPI.get({ params: { library: 14 } });
};
export const useFetchSpaceScores = () => {
  return useQuery({
    queryKey: ["space-scores"],
    queryFn: fetchSpaceScores,
  });
};
