import { yupResolver } from "@hookform/resolvers/yup";
import { ContactAPI } from "api";
import useQuery from "hooks/useQuery";
import React, { useEffect } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { emailRegex } from "utils/Validations";
import { customSettings } from "utils/customConfig";
import * as yup from "yup";

const schema = yup
  .object({
    name: yup.string().required("This field is required."),
    phone: yup.string().required("This field is required."),
    mobile: yup.string().required("This field is required."),
    email: yup
      .string()
      .required("This field is required.")
      .matches(emailRegex, "Enter a valid email address."),
    gender: yup.string().required("This field is required."),
    age: yup.string().required("This field is required."),
    address: yup.string().required("This field is required."),
    country: yup.string().required("This field is required."),
    state: yup.string().required("This field is required."),
    city: yup.string(),
    zipCode: yup.string().required("This field is required."),
    active: yup.boolean(),
  })
  .required();

const PatientBasicInformation = () => {
  // const {
  //   register,
  //   getValues,
  //   watch,
  //   formState: { errors },
  // } = useFormContext();

  const navigate = useNavigate();
  const formId = useQuery().get("id");

  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let myData = { ...data };
    {
      myData.id = location?.state ? location?.state?.id : 0;
      myData.designation = "";
      myData.dob = "2024-06-06T10:55:43.879Z";
    }

    try {
      await ContactAPI.post(myData);
      toast.success("Record updated successfully", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something went wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      reset(location.state);
    }
  }, []);

  return (
    <>
      <Card.Header as="h6" className="bg-body-tertiary mb-2">
        Basic information
      </Card.Header>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label={
                    <span>
                      Name <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    autoFocus
                    isInvalid={!!errors.name}
                    {...register("name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label={
                    <span>
                      Phone <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className=""
                >
                  <Form.Control
                    type="phone"
                    placeholder=""
                    autoComplete="off"
                    isInvalid={!!errors.phone}
                    {...register("phone")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label={
                    <span>
                      Mobile <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className=""
                >
                  <Form.Control
                    type="phone"
                    placeholder=""
                    autoComplete="off"
                    isInvalid={!!errors.mobile}
                    {...register("mobile")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label={
                    <span>
                      Email <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className=""
                >
                  <Form.Control
                    type="email"
                    placeholder=""
                    autoComplete="off"
                    isInvalid={!!errors.email}
                    {...register("email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <FloatingLabel
                  controlId="floatingSelectGrid"
                  label={
                    <span>
                      Gender <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <Form.Select
                    aria-label="gender"
                    isInvalid={!!errors.gender}
                    {...register("gender")}
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.gender?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label={
                    <span>
                      Age <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    isInvalid={!!errors.age}
                    {...register("age")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.age?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label={
                    <span>
                      Address <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    isInvalid={!!errors.address}
                    {...register("address")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <FloatingLabel
                  controlId="floatingSelectGrid"
                  label={
                    <span>
                      City <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <Form.Select
                    aria-label="city"
                    isInvalid={!!errors.city}
                    {...register("city")}
                  >
                    <option value="">Select</option>
                    <option value="Lahore">Lahore</option>
                    {/* {cities?.data.data.map((d) => (
                      <option value={d.id}>{d.name}</option>
                    ))} */}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.city?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label={
                    <span>
                      Zip Code <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className=""
                >
                  <Form.Control
                    type="number"
                    placeholder=""
                    autoComplete="off"
                    isInvalid={!!errors.zipCode}
                    {...register("zipCode")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zipCode?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label={
                    <span>
                      State <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    isInvalid={!!errors.state}
                    {...register("state")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.state?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label={
                    <span>
                      Country <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  className=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    isInvalid={!!errors.country}
                    {...register("country")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.country?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>

            {/* <Col md="12">
              <Form.Check
                type="switch"
                id="defaultSwitch"
                label="Active"
                defaultChecked={!location.state}
                isInvalid={!!errors.active}
                {...register("active")}
              />
            </Col> */}

            {/*   <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  isInvalid={!!errors.userName}
                  {...register("userName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

            {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
          </Row>
          <Row className="gx-2 gy-3 my-1 ">
            <Col className="d-flex justify-content-end">
              <Button
                variant="link"
                className="text-secondary fw-medium p-0 me-3"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Ok
              </Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default PatientBasicInformation;
