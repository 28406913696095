import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchLocations = () => {
  return LibraryAPI.get({ params: { library: 21 } });
};
export const useFetchLocations = () => {
  return useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocations,
  });
};
