import { useQuery } from "@tanstack/react-query";
import { ContactsAPI } from "api";

const fetchPatients = () => {
  return ContactsAPI.get({ params: { entityID: 0 } });
};
export const useFetchPatients = () => {
  return useQuery({
    queryKey: ["patients"],
    queryFn: fetchPatients,
  });
};
