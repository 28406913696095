import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchVisibilities = () => {
  return LibraryAPI.get({ params: { library: 12 } });
};
export const useFetchVisibilities = () => {
  return useQuery({
    queryKey: ["visibilities"],
    queryFn: fetchVisibilities,
  });
};
