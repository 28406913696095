import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

// let typeOptions = [
//   "Liquids",
//   "Tablets",
//   "Capsules",
//   "Topical Medications",
//   "Suppositories",
//   "Drops",
//   "Inhalers",
//   "Injections",
//   "Implants",
// ];

const options = [
  { value: "Liquids", label: "Liquids" },
  { value: "Tablets", label: "Tablets" },
  { value: "Capsules", label: "Capsules" },
  { value: "Topical Medications", label: "Topical Medications" },
  { value: "Suppositories", label: "Suppositories" },
  { value: "Drops", label: "Drops" },
  { value: "Inhalers", label: "Inhalers" },
  { value: "Injections", label: "Injections" },
  { value: "Implants", label: "Implants" },
];

const RXBasicInformation = () => {
  const {
    register,
    setValue,
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { state } = useLocation();
  const navigate = useNavigate();
  // const [typeValue, setTypeValue] = useState(state?.type);

  // useEffect(() => {
  //   setValue("type", typeValue);
  // }, [typeValue, setValue]);

  // const handleInputChange = (event) => {
  //   setTypeValue(event.target.value);
  // };

  // Register the input name with React Hook Form
  useEffect(() => {
    register("type", { required: true });
  }, [register]);

  useEffect(() => {
    if (state) {
      setValue("type", state.type);
    }
  }, [state, setValue]);

  const handleSelectChange = (selectedOption) => {
    setValue("type", selectedOption ? selectedOption.value : "");
    if (selectedOption) {
      clearErrors("type");
    }
  };

  const handleInputFocus = () => {
    clearErrors("type");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: errors.type ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: errors.type ? "red" : provided["&:hover"].borderColor,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black", // Set the text color of the selected value to black
      padding: "7px",
    }),
    placeholder: (provided) => ({
      ...provided,
      padding: "7px",
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
      padding: "7px", // Set the input text color to black
    }),

    menu: (provided) => ({
      ...provided,
      color: "black",
      zIndex: 9999, // Adjust the zIndex as needed
    }),
  };

  let initialSelectedOption = options.find(
    (option) => option.value === state?.type
  );

  if (initialSelectedOption == undefined) {
    initialSelectedOption = state
      ? { label: state?.type, value: state?.type }
      : undefined;
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              {/* <FloatingLabel controlId="floatingSelectGrid" label="Type"> */}
              {/* <Form.Select
                  aria-label="type"
                  autoFocus
                  isInvalid={!!errors.type}
                  {...register("type")}
                >
                  <option value="">Select</option>
                  <option value="Liquids">Liquids</option>
                  <option value="Tablets">Tablets</option>
                  <option value="Capsules">Capsules</option>
                  <option value="Topical Medications">
                    Topical Medications
                  </option>
                  <option value="Suppositories">Suppositories</option>
                  <option value="Drops">Drops</option>
                  <option value="Inhalers">Inhalers</option>
                  <option value="Injections">Injections</option>
                  <option value="Implants">Implants</option>
                </Form.Select> */}
              {/* </FloatingLabel> */}
              {/* <CreatableSelect isClearable {...register("type")} /> */}
              <CreatableSelect
                options={options}
                isClearable
                placeholder={
                  <span>
                    Type{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>*</span>
                  </span>
                }
                className={`${errors.type ? "is-invalid" : ""}`}
                styles={customStyles}
                onChange={handleSelectChange}
                onFocus={handleInputFocus}
                onInputChange={handleInputFocus}
                defaultValue={initialSelectedOption}
              />
              <Form.Control.Feedback type="invalid">
                {errors.type?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Type"
                className=""
              >
                <Form.Control
                  type="text"
                  list="options"
                  placeholder=""
                  autoFocus
                  autoComplete="off"
                  {...register("type")}
                  // value={typeValue || ""}
                  isInvalid={!!errors.type}
                  // onChange={handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
                <datalist id="options">
                  {typeOptions.map((d) => (
                    <option key={d} value={d} />
                  ))}
                </datalist>
              </FloatingLabel>
            </Form.Group> */}
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label={
                  <span>
                    Name <span style={{ color: "red" }}>*</span>
                  </span>
                }
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  autoComplete="off"
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Check
              type="switch"
              id="defaultSwitch"
              label="Active"
              defaultChecked={!state}
              isInvalid={!!errors.active}
              {...register("active")}
            />
          </Col>
          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.email}
                  {...register("email")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  isInvalid={!!errors.userName}
                  {...register("userName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Role">
                <Form.Select
                  aria-label="role"
                  isInvalid={!!errors.role}
                  {...register("role")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.role?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
        <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Ok
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RXBasicInformation;
