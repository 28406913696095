import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchSpaceClasses = () => {
  return LibraryAPI.get({ params: { library: 13 } });
};
export const useFetchSpaceClasses = () => {
  return useQuery({
    queryKey: ["space-classes"],
    queryFn: fetchSpaceClasses,
  });
};
